import { render, staticRenderFns } from "./wxChat.vue?vue&type=template&id=64fe8516&scoped=true&"
import script from "./wxChat.vue?vue&type=script&lang=js&"
export * from "./wxChat.vue?vue&type=script&lang=js&"
import style0 from "./wxChat.vue?vue&type=style&index=0&id=64fe8516&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64fe8516",
  null
  
)

export default component.exports