<template>
  <div class="index">
    <van-nav-bar @click-left="onClickLeft" title="咨询师登录" left-arrow />
    <div class="input">
      <van-field
        v-model="tel"
        autosize
        label="手机号:"
        clearable
        placeholder="请输入手机号"
      />
    </div>
    <div class="input">
      <van-field
        v-model="pasd"
        autosize
        label="密码:"
        clearable
        placeholder="请输入密码"
      />
    </div>
    <div class="texts">
      <span>初始密码为手机号后六位</span>
    </div>
    <div class="btn">
      <van-button @click="phoneLogin"  style="width: 90%;" type="info">登录</van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { weComLogin } from '@/api/login'
export default {
  data () {
    return {
      checked: false,
      tel: '',
      pasd: ''
    }
  },
  methods: {
    phoneLogin () {
      if (this.tel === '') {
        Toast('手机号码不能为空')
      } else if (this.pasd === '') {
        Toast('密码不能为空')
      } else {
        weComLogin(this.tel, this.pasd).then(res => {
          if (res.data.identity === 0) {
            window.localStorage.setItem('refresh_token', res.data.refresh_token.token)
            window.localStorage.setItem('avatar', res.data.avatar)
            window.localStorage.setItem('wechat_qr', res.data.wechat_qr)
            window.localStorage.setItem('phone', res.data.phone)
            window.localStorage.setItem('access_token', res.data.access_token.token)
            window.localStorage.setItem('gender', res.data.gender)
            window.localStorage.setItem('identity', res.data.identity)
            window.localStorage.setItem('name', res.data.name)
            this.$router.replace('/home')
            Toast.success('登陆成功')
          } else {
            window.localStorage.setItem('refresh_token', res.data.refresh_token.token)
            window.localStorage.setItem('avatar', res.data.avatar)
            window.localStorage.setItem('phone', res.data.phone)
            window.localStorage.setItem('access_token', res.data.access_token.token)
            window.localStorage.setItem('gender', res.data.gender)
            window.localStorage.setItem('identity', res.data.identity)
            window.localStorage.setItem('nickName', res.data.name)
            this.$router.replace('/counselorHome')
            Toast.success('登陆成功')
          }
        })
      }
    },
    onClickLeft () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
:deep {
  .van-nav-bar__title {
    font-weight: bold;
}
  .van-button--info {
    color: #fff;
    background-color: rgb(43, 84, 163);
    border-color: rgb(43, 84, 163);
    border-radius: 9px;
  }
  .van-cell {
    border-radius: 10px;
    width: 95%;
    margin-left: 10px;
    margin-left: 10px;
}
}
.texts{
  margin-top: 10px;
  margin-left: 15px;
  color: #646566;
}
.index {
  min-height: 100vh;
  margin: auto;
  background-color: rgb(238, 238, 238);
  height: 100%;
  width: 100%;
  .input{
    margin-top: 10px;
  }
  .btn{
    text-align: center;
    margin-bottom: 10px;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
  }
}
</style>
