import request from '@/utils/request'
// 查询咨询师
export function counselorPage (params) {
  return request({
    url: '/api/consultant/page',
    method: 'get',
    params
  })
}
// 咨询师详情
export function counselorDetail (id) {
  return request({
    url: `/api/consultant/detail/${id}`,
    method: 'get'
  })
}
// 订单查询分页
export function consultantOrderPage (data) {
  return request({
    url: '/api/consultantOrder/page',
    method: 'post',
    data
  })
}
// 已预约订单详情
export function initOrderDetail (orderId) {
  return request({
    url: `/api/consultantOrder/detail/${orderId}`,
    method: 'get'
  })
}
// 取消订单
export function consultantOrderCancel (data) {
  return request({
    url: '/api/consultantOrder/cancel',
    method: 'post',
    data
  })
}
// 签退
export function consultantOrderSignOut (orderId) {
  return request({
    url: `/api/consultantOrder/signOut/${orderId}`,
    method: 'get'
  })
}
// 签到
export function consultantOrderSignIn (orderId) {
  return request({
    url: `/api/consultantOrder/signIn/${orderId}`,
    method: 'get'
  })
}
// 即将开始的订单
export function consultantPageWillStartOrder (data) {
  return request({
    url: '/api/consultantOrder/pageWillStartOrder',
    method: 'post',
    data
  })
}
// 修改订单预约时间
export function consultantChangeReserveTime (orderId, timeId) {
  return request({
    url: `/api/consultantOrder/changeReserveTime/${orderId}/${timeId}`,
    method: 'get'
  })
}

// 评分
export function consultantOrderScore (orderId, score) {
  return request({
    url: `/api/consultantOrder/score/${orderId}/${score}`,
    method: 'get'
  })
}
// 获取订单反馈
export function consultantOrderGetFeedBack (orderId) {
  return request({
    url: `/api/consultantOrder/getFeedBack/${orderId}`,
    method: 'get'
  })
}
// 获得我的统计
export function consultantGetMyInfo () {
  return request({
    url: '/api/consultant/getCount',
    method: 'get'
  })
}
// 我的信息
export function consultantGetInfo () {
  return request({
    url: '/api/consultant/getInfo',
    method: 'get'
  })
}
// 修改微信号
export function consultantChangeWeChat (wechat) {
  return request({
    url: `/api/consultant/changeWeChat/${wechat}`,
    method: 'get'
  })
}
// 修改性别
export function consultantChangeGender (gender) {
  return request({
    url: `/api/consultant/changeGender/${gender}`,
    method: 'get'
  })
}
// 修改名称
export function consultantChangeName (name) {
  return request({
    url: `/api/consultant/changeName/${name}`,
    method: 'get'
  })
}
