<template>
  <div class="index" style="background-color: rgb(232, 232, 232)">
    <div v-if="showS">
      <van-nav-bar
        fixed
        placeholder
        @click-left="onClickLeft"
        title="确认预约"
        left-arrow
      />
      <div class="cards">
        <div class="msg">
          <span>咨询信息</span>
        </div>
        <div style="display: flex">
          <div class="imgs">
            <van-image
              width="100px"
              height="100px"
              fit="cover"
              :src="details.cover"
            />
          </div>
          <div class="info">
              <div style="display: flex">
                <div>
                  <span style="font-size: 16px; font-weight: 400">{{
                    details.name
                  }}</span>
                </div>
                <div>
                  <img
                    v-if="details.isShowQualificationIcon === true"
                    style="width: 57px; height: 20px; margin: 3px 0 0 7px"
                    src="@/assets/renzhen14.png"
                  />
                </div>
              </div>
            <div style="font-size: 12px; margin: 5px 0 5px 0; text-align: left">
              <span>{{ details.introduce }}</span>
            </div>

            <div style="font-size: 12px; margin: 5px 0 5px 0; text-align: left">
              <span class="tag">{{ details.consultantTypes }}</span>
            </div>
            <div style="display: flex; margin: 7px 0 7px 0">
              <div style="display: flex;margin-left: auto;">
                <div>
                  <van-tag plain type="warning">中铁建员工可享优惠</van-tag>
                </div>
                <div>
                  <van-tag plain type="warning" style="margin-left: 3px;">首次优惠</van-tag>
                </div>
              </div>
              <div class="mony" style="margin-right: 11px;">
                ¥<span style="font-size: 18px">{{ details.normalPrice }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="time">
          <div><span>预约咨询时间</span></div>
          <div>
            <span class="times"
              >{{ timeText }}</span
            >
          </div>
        </div>
      </div>
      <div class="personInfo">
        <div class="msg">
          <span>来访信息</span>
        </div>
        <div>
          <div class="flexinfo">
            <div class="leftText" style="padding-left: 11px">
              <span>来访人姓名</span>
            </div>
            <div style="padding-right: 11px">
              <span>{{ from.name }}</span>
            </div>
          </div>
          <div class="flexinfo">
            <div class="leftText" style="padding-left: 11px">
              <span>来访人性别</span>
            </div>
            <div style="padding-right: 11px">
              <span>{{ from.sex }}</span>
            </div>
          </div>
          <div class="flexinfo">
            <div class="leftText" style="padding-left: 11px">
              <span>联系方式</span>
            </div>
            <div style="padding-right: 11px">
              <span>{{ from.phone }}</span>
            </div>
          </div>
          <div class="flexinfo">
            <div class="leftText" style="padding-left: 11px">
              <span>咨询方向</span>
            </div>
            <div style="padding-right: 11px">
              <span>{{ from.direction }}</span>
            </div>
          </div>
          <div class="flexinfo">
            <div class="leftText" style="padding-left: 11px">
              <span>微信号码</span>
            </div>
            <div style="padding-right: 11px">
              <span  v-if="from.code">{{ from.code }}</span>
              <span v-else>暂无</span>
            </div>
          </div>
          <div class="time">
            <div
              class="leftText"
              style="text-align: left; width: 55%; padding-left: 11px"
            >
              <span>备注</span>
            </div>
            <div style="text-align: right; width: 45%; padding-right: 11px">
              <span class="times" v-if="from.message">{{ from.message }}</span>
              <span class="times" v-else>无备注</span>
            </div>
          </div>
          <div class="flexinfo" style="padding-bottom: 10px">
            <div class="leftText" style="padding-left: 11px">
              <span>微信二维码</span>
            </div>
            <div style="padding-right: 11px">
              <div v-if="img" class="imgs">
                <van-image width="64px" height="64px" fit="cover" :src="img" />
              </div>
              <div v-else class="imgs">
                暂无
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="personInfo">
        <div class="msg">
          <span>价格明细</span>
        </div>
        <div>
          <div style="padding-top: 0" class="flexinfo">
            <div class="leftText" style="padding-left: 11px">
              <span>订单总价</span>
            </div>
            <div style="padding-right: 11px">
              <span>¥{{ details.normalPrice }}</span>
            </div>
          </div>
          <div class="flexinfo">
            <div class="leftText" style="padding-left: 11px">
              <span>订单服务次数</span>
            </div>
            <div style="padding-right: 11px">
              <span>1次</span>
            </div>
          </div>
          <div class="flexinfo">
            <div class="leftText" style="padding-left: 11px">
              <span>支付方式</span>
            </div>
            <div style="padding-right: 11px">
              <span v-if="cardId === ''"
                >卡密支付
               </span>
              <span v-else
                >已选卡密 </span>
            </div>
          </div>
          <div class="flexinfo" style="padding-bottom: 10px">
            <div class="leftText" style="padding-left: 11px">
              <span>合计</span>
            </div>
            <div style="padding-right: 11px">
              <span v-if="cardId === ''">¥{{ details.normalPrice }}</span>
              <span v-else>¥0</span>
            </div>
          </div>
        </div>
      </div>
      <div style="height:90px">
      </div>
      <div class="btn" v-if="cardId === ''">
        <van-button
          @click="card"
          type="primary"
          style="width: 95%"
          size="normal"
          >选择卡密</van-button
        >
        <van-button
          @click="shows = true"
          type="primary"
          style="width: 95%"
          size="normal"
          >联系客服</van-button
        >
      </div>
      <div class="btn" v-else>
        <van-button
          @click="card"
          type="primary"
          style="width: 95%"
          size="normal"
          >重选卡密</van-button
        >
        <van-button
          @click="yuyue"
          type="primary"
          style="width: 95%"
          size="normal"
          >预约支付</van-button
        >
      </div>
    </div>
    <div v-else class="indexS">
      <van-nav-bar
        fixed
        placeholder
        @click-left="onClickLefts"
        title="选择卡密"
        left-arrow
      />
      <van-empty
        style="background-color: #fff; height: 100vh"
        v-if="total === 0"
        image="error"
        description="暂无可用卡密"
      />

      <div v-if="total > 0">
        <van-list
        :immediate-check='false'
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          offset="40"
        >
          <div
            @click="selectCarmi(item)"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="user_card">
      <div class="numinfo">
        <div class="num">
          <div>
            <img
              style="width: 30px; height: 30px"
              src="@/assets/kahao.png"
              alt=""
              srcset=""
            />
          </div>
          <div style="padding-top: 5px; margin-left: 10px">
            <span>卡号</span>
            <span style="margin-left: 4px">{{item.cardNumber}}</span>
          </div>
        </div>
        <div style="padding-top: 5px; color: rgb(1, 81, 166); font-size: 14px;">
          <span v-if="item.isUsed === false">
            <span v-if="item.status === 1">未启用</span>
            <span v-if="item.status === 2">待使用</span>
            <span v-if="item.status === 3">已过期</span>
          </span>
          <span v-else>已使用</span>
        </div>
      </div>
      <van-divider />
      <div style="padding-bottom: 10px;">
        <div>
          <div style="padding: 0 12px 0 12px;" class="flexinfo">
            <div class="leftText">
              <span>绑定成功时间</span>
            </div>
            <div style="text-align: right">
              <span>{{item.bindTime}}</span>
            </div>
          </div>
          <div class="flexinfo">
            <div class="leftText">
              <span>服务次数</span>
            </div>
            <div style="text-align: right">
              <span>{{item.serviceTimes}}次</span>
            </div>
          </div>
          <div class="flexinfo">
            <div class="leftText">
              <span>有效起始日期</span>
            </div>
            <div style="text-align: right">
              <span>{{item.startTime}}</span>
            </div>
          </div>
          <div class="flexinfo" style="padding-bottom: 7px;">
            <div class="leftText">
              <span>有效结束日期</span>
            </div>
            <div style="text-align: right">
              <span>{{item.endTime}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
          </div>
        </van-list>
      </div>
    </div>
    <!-- 预约支付弹窗 -->
    <van-popup v-model="show">
      <div class="succee">
        <div style="margin-top: 20px">
          <img
            style="width: 40px"
            src="@/assets/succeed.png"
            alt=""
            srcset=""
          />
        </div>
        <div>预约成功</div>
        <hr />
        <div style="margin-top: 20px">您已完成预约,请等待咨询师确认...</div>
        <div style="margin-top: 20px">
          <van-button
            @click="Ikonw"
            style="width: 40%; height: 30px; border-radius: 10px"
            type="primary"
            size="normal"
            >我知道了</van-button
          >
        </div>
      </div>
    </van-popup>
    <!-- 联系客服弹窗 -->
    <van-popup
      style="border-radius: 1px"
      v-model="shows"
      position="bottom"
      :style="{ height: '20%' }"
    >
      <div style="margin-top: 20px;background-color: transparent;">
        <van-button
          @click="phone"
          style="width: 90%; border-radius: 10px;background-color: #fff;color:#333;border: 0.02667rem solid #fff;"
          type="primary"
          size="normal"
          >拨打客服电话: <span style="color:rgb(1,81,166);">198****8721</span></van-button
        >
      </div>
      <div style="margin-top: 10px">
        <van-button
          @click="shows = false"
          style="width: 90%; border-radius: 10px;background-color: #fff;color:#333;border: 0.02667rem solid #fff;"
          type="primary"
          size="normal"
          >取消</van-button
        >
      </div>
    </van-popup>
  </div>
</template>
<script>
import { getUnusedCard } from '@/api/card'
import { consultantOrder } from '@/api/appointment'
import { counselorDetail } from '@/api/user'
import { Dialog, Toast } from 'vant'
export default {
  data () {
    return {
      details: {},
      showS: true,
      shows: false,
      show: false,
      active: 0,
      id: '',
      from: {
        code: '',
        name: '',
        sex: '',
        direction: '',
        phone: ''
      },
      directionId: '',
      timeText: '',
      cardId: '',
      img: '',
      current: 1,
      size: 10,
      list: [],
      loading: false,
      finished: false,
      gender: '',
      timeId: '',
      total: ''
    }
  },
  created () {
    Toast.loading({
      message: '加载中...',
      forbidClick: true
    })
    this.id = this.$route.query.id
    this.Detail()
  },
  methods: {
    phone () {
      window.location.href = 'tel:17602836084'
    },
    selectCarmi (item) {
      Dialog.confirm({
        title: '选择卡密',
        message: '确定选择此卡密吗？'
      })
        .then(() => {
          this.cardId = item.id
          this.showS = true
          Toast.success('选择成功')
        })
        .catch(() => {
          // on cancel
        })
    },
    card () {
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      })
      this.list = []
      this.List()
      this.showS = false
    },
    Detail () {
      counselorDetail(this.id).then((res) => {
        this.timeId = window.localStorage.getItem('timeId')
        this.img = window.localStorage.getItem('imgLink')
        this.from.code = window.localStorage.getItem('code')
        this.from.message = window.localStorage.getItem('message')
        this.timeText = window.localStorage.getItem('timeText')
        this.directionId = window.localStorage.getItem('directionId')
        this.from.name = window.localStorage.getItem('nameS')
        this.from.sex = window.localStorage.getItem('sexS')
        this.from.direction = window.localStorage.getItem('directionS')
        this.from.phone = window.localStorage.getItem('phoneS')
        res.data.data.consultantTypes =
          res.data.data.consultantTypes.join(' | ')
        this.details = res.data.data
      })
    },
    onLoad () {
      this.current++
      this.List()
    },
    List () {
      getUnusedCard({ current: this.current, size: this.size }).then((res) => {
        this.list = this.list.concat(res.data.data.records)
        this.total = res.data.data.total
        if (this.list.length === res.data.data.total) {
          this.loading = true
          this.finished = true
        }
      })
    },
    Ikonw () {
      this.show = false
      this.$router.replace('/reservation')
    },
    onClickLefts () {
      this.list = []
      this.showS = true
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    yuyue () {
      Dialog.confirm({
        title: '确定',
        message: '确定支付吗？'
      })
        .then(() => {
          if (this.from.sex === '男') {
            this.gender = 1
          } else {
            this.gender = 2
          }
          const data = {
            counselorId: this.$route.query.id,
            cardId: this.cardId,
            comingName: this.from.name,
            comingPhone: this.from.phone,
            consultTypeId: this.directionId,
            comingGender: this.gender,
            timeId: this.timeId,
            wechatQr: this.img,
            wechat: this.from.code,
            note: this.from.message
          }
          consultantOrder(data).then((res) => {
            this.show = true
            window.localStorage.removeItem('timeId')
            window.localStorage.removeItem('imgLink')
            window.localStorage.removeItem('nameS')
            window.localStorage.removeItem('sexS')
            window.localStorage.removeItem('directionS')
            window.localStorage.removeItem('phoneS')
            window.localStorage.removeItem('message')
            window.localStorage.removeItem('timeText')
            window.localStorage.removeItem('timeId')
          })
        })
        .catch(() => {
          // on cancel
        })
    }
  }
}
</script>

<style lang="scss" scoped>
:deep {
  .van-divider {
    width: 90%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 15px;
}
  .van-nav-bar__title {
    font-weight: bold;
  }
  .van-popup--center {
    text-align: center;
  }
  .van-popup--bottom {
    text-align: center;
    background: transparent;
}
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160%;
  width: 100%;
}
.index {
  height: 100%;
  padding-bottom: 10px;
  min-height: 100vh;
}
.indexS {
  background-color: #f5f6f8;
  overflow: hidden;
  height: 100%;
  min-height: 100vh;
  .user_card {
    height: 100%;
    width: 95%;
    background-color: #fff;
    border-radius: 10px;
    margin: auto;
    margin-bottom: 10px;
    margin-top: 10px;
    .numinfo {
      display: flex;
      justify-content: space-between;
      padding: 10px 15px 0 15px;
      .num {
        display: flex;
      }
    }
    .leftText {
      color: rgb(104, 104, 104);
      font-weight: 400;
      font-size: 14px;
    }
    .flexinfo {
      font-size: 15px;
      padding: 10px 12px 0 12px;
      display: flex;
      justify-content: space-between;
    }
    .time {
      padding-top: 5px;
      display: flex;
      color: rgb(104, 104, 104);
    }
  }
}
.btn {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  text-align: center;
}
.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
:deep {
  .van-popup--center {
    border-radius: 10px;
    width: 90%;
  }
  .van-button--primary {
    color: #fff;
    height: 40px;
    margin-bottom: 10px;
    background-color: rgb(43, 84, 163);
    border-radius: 5px;
    border: 1px solid rgb(43, 84, 163);
  }
  .van-tag--warning.van-tag--plain {
    color: rgb(255, 173, 63);
    border-radius: 5px;
    font-size: 11px;
  }
  .van-button--info {
    color: #fff;
    height: 29px;
    background-color: rgb(43, 84, 163);
    border-color: rgb(43, 84, 163);
    border-radius: 29px;
    margin: 0 10px 10px 0;
    width: 83px;
  }
}
.succee {
  background-color: #fff;
  width: 95%;
}
.personInfo {
  width: 95%;
  margin: auto;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  font-size: 14px;
  .leftText {
    color: rgb(104, 104, 104);
    font-weight: 400;
  }
  .flexinfo {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .time {
    padding-top: 5px;
    display: flex;
    color: rgb(104, 104, 104);
  }
}
.msg {
  font-size: 17px;
  font-weight: 600;
  text-align: left;
  padding: 9px 0 6px 11px;
}
.cards {
  width: 95%;
  margin: auto;
  margin-top: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  .imgs {
    margin-left: 13px;
    margin-top: 5px;
  }
  .info {
    font-size: 13px;
    margin: 5px 0 7px 7px;
    text-align: left;
    width: 100%;
  }
  .mony {
    flex: 1;
  text-align: right;
  margin-top: 6px;
    color: rgb(1, 81, 166);
  }
  .time {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding: 0 10px 15px 12px;
    color: rgb(104, 104, 104);
    .times {
      color: rgb(43, 84, 163);
    }
  }
}
</style>
