<template>
  <div class="index">
    <van-nav-bar fixed placeholder @click-left="onClickLeft" title="我的反馈" left-arrow />
    <!-- <reservedS></reservedS> -->
    <van-tabs v-model="active">
      <van-tab title="待反馈">
        <div v-if="active === 0" class="indexH">
          <consultS></consultS>
        </div>
      </van-tab>
      <van-tab title="已反馈">
         <div v-if="active === 1" class="indexH">
          <feedback></feedback>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import feedback from './feedback/feedbackS'
import consultS from './feedback/consultS'
export default {
  components: {
    consultS,
    feedback
  },
  data () {
    return {
      active: 0
    }
  },
  methods: {
    onClickLeft () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
:deep {
  .van-tabs--line .van-tabs__wrap {
    top: 45px;
    position: fixed;
    height: 44px;
    width: 100%;
    z-index: 1000;
    margin-bottom: 40px;
}
  .van-tab--active {
    color: rgb(43, 84, 163);
    font-size: 16px;
    font-weight: 500;
  }
  .van-tabs__line {
    background-color: rgb(43, 84, 163);
  }
  .van-nav-bar__title {
    font-weight: bold;
  }
  .van-button--info {
    color: #fff;
    background-color: rgb(43, 84, 163);
    border-color: rgb(43, 84, 163);
    border-radius: 29px;
    height: 29px;
  }
}
.index {
  margin: auto;
  background-color: rgb(232, 232, 232);
  height: 100%;
  width: 100%;
  .indexH{
    margin-top: 50px;
  }
}
</style>
