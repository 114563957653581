<template>
  <div style="width: 100%; background-color: rgb(245, 246, 248); height: 100%;padding-bottom: 10px;min-height: 100vh;">
    <van-nav-bar
      fixed
      placeholder
      @click-left="onClickLeft"
      :title="details.name"
      left-arrow
    />
    <div>
      <div class="user_card">
        <van-image
          fit="cover"
          style="border-radius: 10px 10px 0 0; height: 175px; width: 100%"
          :src="details.cover"
        />
        <div style="margin: 0 10px 0 10px">
          <div style="width: 100%; height: 34px">
            <div class="left">
              <div>
                <span style="font-size: 18px">{{ details.name }}</span>
                <img
                  v-if="details.isShowQualificationIcon === true"
                  style="width: 55px; height: 20px; margin-left: 10px"
                  src="@/assets/renzhen14.png"
                  mode=""
                />
              </div>
            </div>
            <div class="mony">
              ¥<span style="font-size: 18px">{{ details.normalPrice }}</span>
            </div>
          </div>
          <div style="font-size: 15px; text-align: left;margin: 7px 0 7px 0;">
            <span>{{ details.introduce }}</span>
          </div>
          <div style="font-size: 13px; margin: 7px 0 7px 0; text-align: left">
            <div class="tags">
              <span class="tag">{{ details.consultantTypes }}</span>
            </div>
          </div>
          <div style="display: flex; margin: 7px 0 7px 0">
            <div>
              <van-tag plain type="warning">中铁建员工可享优惠</van-tag>
            </div>
            <div style="margin-left: 7px">
              <van-tag plain type="warning">首次优惠</van-tag>
            </div>
          </div>

          <div class="cards">
            <div style="text-align: left; margin-left: 15px; padding-top: 10px">
              <span class="num">咨询经验</span>
            </div>
            <div class="cards_item">
              <div>
                <div>
                  <span style="font-size: 14px;">{{ details.serviceTimes }}</span
                  >+
                </div>
                <div>
                  <span class="nums">咨询次数</span>
                </div>
              </div>
              <div>
                <div>
                  <span style="font-size: 14px;">{{ details.workTime }}</span
                  >+
                </div>
                <div>
                  <span class="nums">从业年限</span>
                </div>
              </div>
            </div>
          </div>
          <div class="times">
            <div style="">
              <img
                style="width: 12px; height: 12px"
                src="@/assets/time.png"
                mode=""
              />
              <span style="margin-left: 4px">可约时间:</span>
            </div>
            <div>
              <span style="color: rgb(154, 154, 154)">查看可约时间</span>
            </div>
          </div>
          <div style="width: 100%; padding: 11px 0 13px 0;">
            <span
              v-for="(time, index1) in details.times"
              :key="index1"
              class="tag_left"
            >
              <van-tag type="success">{{ time.day.slice(5,100) }} {{ time.week }}</van-tag>
            </span>
          </div>
        </div>
      </div>
      <div class="info_card">
        <div class="info1">
          <span>咨询师简介</span>
        </div>
        <div class="info2">
          <span class="textIntroduce" v-if=" details.textIntroduce !== ''" v-html="details.textIntroduce"></span>
          <span v-else>
            看到这个页面的你，可能正在经历一些令你难受的体验和处境，遭遇情绪的波动、关系的考验、困难的决定.....可能你尝
            试了很多方式，还是感到挥之不去的焦虑和忧心。也许你犹豫着是否要进行心理咨询，或者还没找到合适的心理咨询师。此
            刻我可以为你做的是，提供一些我对心理咨询的看法和基本介绍，希望能协助你更好地进行选择。
          </span>
        </div>
      </div>
      <div style="height:50px"></div>
      <div class="btn">
        <van-button
          @click="yuyue"
          type="primary"
          style="width: 95%"
          size="normal"
          >立刻预约</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { counselorDetail } from '@/api/user'
export default {
  data () {
    return {
      details: {},
      id: ''
    }
  },
  created () {
    Toast.loading({
      message: '加载中...',
      forbidClick: true
    })
    const id = window.localStorage.setItem('counselorId', this.$route.query.id)
    if (id === undefined) {
      window.localStorage.setItem('counselorId', this.$route.query.id)
      this.id = this.$route.query.id
      counselorDetail(this.id).then((res) => {
        res.data.data.consultantTypes =
          res.data.data.consultantTypes.join(' | ')
        this.details = res.data.data
      })
    } else {
      counselorDetail(id).then((res) => {
        this.details = res.data.data
      })
    }
  },
  methods: {
    yuyue () {
      this.$router.push(`/appointment?id=${this.id}`)
    },
    onClickLeft () {
      window.localStorage.removeItem('counselorId')
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
:deep {
  .van-image__img {
    border-radius: 10px 10px 0 0;
}
  .van-nav-bar__title {
    font-weight: bold;
  }
  .van-tag--warning.van-tag--plain {
    color: rgb(255, 173, 63);
    border-radius: 5px;
    padding: 3px 3px 3px 3px;
  }
  .van-button--primary {
    color: #fff;
    background-color: rgb(43, 84, 163);
    border-radius: 5px;
    border: 1px solid rgb(43, 84, 163);
    margin-top: 7px;
  }
  .van-tag--success {
    background-color: rgb(239, 241, 255);
    color: rgb(43, 84, 163);
    border-radius: 5px;
  }
}
.textIntroduce{
  img{
    width: 90px !important;
  }
}
.btn {
  position:fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  margin-bottom: 10px;
  text-align: center;
}
.info_card {
  margin: auto;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 7px;
  padding: 10px 10px 10px 10px;
  width: 90%;
  .info1 {
    text-align: left;
    font-size: 15px;
    font-weight: 500;
  }
  .info2 {
    text-align: left;
    font-size: 12px;
    margin-top: 10px;
    color: rgb(87, 87, 87);
  }
}
.user_card {
  width: 95%;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  margin-top: 7px;
  .cards {
    background: linear-gradient(rgba(239,247,255,1) 0%,rgba(239,241,255,1) 100%);
    border-radius: 10px;
    .num {
      font-weight: 600;
      font-size: 15px;
    }
    .cards_item {
      text-align: center;
      color: rgb(43, 84, 163);
      display: flex;
      justify-content: space-between;
      margin: 13px 20px 10px 20px;
      padding-bottom: 25px;
      .nums {
        color: #333;
        font-size: 11px;
      }
    }
  }
  .tag_left {
    margin-left: 6px;
  }
  .left {
    margin-top: 13px;
    display: flex;
    width: 70%;
    float: left;
  }
  .mony {
    margin-top: 13px;
    color: rgb(1, 81, 166);
    float: right;
  }
  .times {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
  }
}
</style>
