<template>
  <div class="home" style="height: 100vh">
    <!-- <van-nav-bar title="登录" left-arrow /> -->
    <div class="btn">
      <div class="infos">
        <van-checkbox v-model="checked"
          >阅读并同意<span>《用户隐私协议》</span></van-checkbox
        >
      </div>
      <div style="margin-bottom: 20px; text-align: center">
        <van-button @click="login" round type="info">企业微信授权登录</van-button>
        <van-button @click="phoneLogin" round type="info">咨询师登录</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { wxLogin } from '@/api/login'
export default {
  data () {
    return {
      checked: false
    }
  },
  methods: {
    phoneLogin () {
      if (this.checked === true) {
        this.$router.push('/phoneLogin')
      } else {
        Toast.fail('请勾选用户协议')
      }
    },
    async login () {
      if (this.checked === true) {
        const res = await wxLogin()
        const url = res.data
        window.open(url, '_self')
      } else {
        Toast.fail('请勾选用户协议')
      }
    }
  },
  created () {
    const token = window.localStorage.getItem('access_token')
    const identity = window.localStorage.getItem('identity')
    if (token !== null) {
      if (identity === '0') {
        this.$router.replace('/home')
      } else {
        this.$router.replace('/counselorHome')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:deep {
  .van-nav-bar__title {
    font-weight: bold;
  }
  .van-nav-bar__left {
    display: none;
  }
  .van-button--info {
    color: #fff;
    height: 40px;
    background-color: rgb(43, 84, 163);
    border-color: rgb(43, 84, 163);
    border-radius: 29px;
    margin: 0 10px 10px 0;
    width: 90%;
  }
}
.home{
  background: url("@/assets/beijing2.png") no-repeat;
  background-position: center;
  background-size: 100%;
}
.btn {
  text-align: center;
  width: 100%;
  position: fixed;
  bottom: 5px;
}
.infos {
  font-size: 15px;
  margin: 0 auto;
  margin-bottom: 10px;
  margin-left: 18%;
}
</style>
