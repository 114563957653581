import request from '@/utils/request'
// 绑定卡密
export function cardBinnding (cardNum, cardPassword) {
  return request({
    url: `/api/card/bindCard/${cardNum}/${cardPassword}`,
    method: 'get'
  })
}
// 绑卡历史
export function cardBindHis (params) {
  return request({
    url: '/api/card/bindHis',
    method: 'get',
    params
  })
}
// 获得未使用的卡片
export function getUnusedCard (params) {
  return request({
    url: '/api/card/getUnusedCard',
    method: 'get',
    params
  })
}
