<template>
  <div class="user_container">
    <van-nav-bar fixed placeholder title="我的" left-arrow />
    <div class="user_header">
      <div>
        <van-image round width="48px" height="48px" fit="cover" :src="avatar" />
      </div>

      <div class="header_action">
        <span>{{name}}</span>
        <div>
          <span style="font-size: 13px">{{ phone }}</span>
        </div>
      </div>

      <div class="header_right">
        <div class="header_right_set">
          <image
            style="height: 25px; width: 25px"
            src="@/assets/user_icon_set.svg"
            mode=""
          ></image>
        </div>
      </div>
    </div>

    <div class="user_main">
      <div class="user_card">
        <div class="title">我的咨询</div>
        <div class="user_me">
          <div @click="underway">
            <div
              style="
                float: left;
                font-size: 14px;
                font-weight: 500;
                text-align: center;
              "
            >
              <div style="margin-bottom: 10px">
                <span class="num">{{ del.orderCount }}</span
                >个
              </div>
              <div>
                <span>签到/签退</span>
              </div>
            </div>
            <div style="float: right; margin: 15px 0 0 10px">
              <img style="width: 15px; height: 15px" src="@/assets/you.png" />
            </div>
          </div>
          <div>
            <img
              style="width: 20px; height: 60px"
              src="@/assets/shu.png"
              mode=""
            />
          </div>
          <div @click="serviceNum">
            <div
              style="
                float: left;
                font-size: 14px;
                font-weight: 500;
                text-align: center;
              "
            >
              <div style="margin-bottom: 10px">
                <span class="num">{{ del.cardCount }}</span
                >次
              </div>
              <div>
                <span>服务次数</span>
              </div>
            </div>
            <div style="float: right; margin: 15px 0 0 10px">
              <img style="width: 15px; height: 15px" src="@/assets/you.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="user_card">
        <div class="title">我的服务</div>
        <div class="user_card_item">
          <div @click="details">
            <img class="item_img" src="@/assets/myInfo.png" mode="" />
            <span>个人信息</span>
          </div>
          <div @click="appointment">
            <img class="item_img" src="@/assets/yuyue.png" mode="" />
            <span>我的预约</span>
          </div>
          <div @click="feedback">
            <img class="item_img" src="@/assets/fankui.png" mode="" />
            <span>我的反馈</span>
          </div>
          <div @click="bindCard">
            <img class="item_img" src="@/assets/bangka.png" mode="" />
            <span>我要绑卡</span>
          </div>
        </div>
      </div>
    </div>
    <van-tabbar placeholder v-model="active">
      <van-tabbar-item @click="home" icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item @click="yuyue" icon="friends-o">咨询师</van-tabbar-item>
      <van-tabbar-item icon="manager-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { consultantGetMyInfo } from '@/api/user'
export default {
  data () {
    return {
      active: 2,
      avatar: '',
      phone: '',
      del: {},
      name: ''
    }
  },
  created () {
    this.name = window.localStorage.getItem('name')
    this.avatar = window.localStorage.getItem('avatar')
    this.phone = window.localStorage.getItem('phone')
    consultantGetMyInfo().then((res) => {
      this.del = res.data.data
    })
  },
  methods: {
    feedback () {
      this.$router.push('/myFeedback')
    },
    appointment () {
      this.$router.push('/reservation')
    },
    underway () {
      this.$router.push('/underway')
    },
    serviceNum () {
      this.$router.push('/bindingHistory')
    },
    bindCard () {
      this.$router.push('/bindingCard')
    },
    details () {
      this.$router.push('/myDetails')
    },
    yuyue () {
      this.$router.push('/preordered')
    },
    home () {
      this.$router.push('/home')
    }
  }
}
</script>

<style lang='scss' scoped>
:deep {
  .van-nav-bar__title {
    font-weight: bold;
  }
  .van-nav-bar__left {
    display: none;
  }
}
.num {
  color: rgb(24, 77, 162);
  margin-right: 5px;
  font-size: 17px;
}
.bg-img {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}
.user_card_item > div > text {
  font-size: 15px;
  font-weight: 500;
  color: #666;
}
.item_img {
  height: 30px;
  width: 30px;
  margin-bottom: 10px;
}
.user_card_item > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 65px;
  text-align: center;
  width: 25%;
  font-size: 14px;
}
.user_card_item {
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
}
.title {
  line-height: 40px;
  padding-left: 11px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #333;
}
.user_card {
  background: #fff;
  border-radius: 0.5rem;
  width: 95%;
  margin: 0 auto;
  margin-top: 10px;
}
.his_img {
  width: 68px;
  height: 68px;
}
.his {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 14px;
  font-weight: 500;
}
.user_me {
  font-size: 12px;
  margin-left: 15px;
  margin-right: 15px;
  padding-bottom: 15px;
  background: #fff;
  border-radius: 0.7rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
}
.user_main {
  height: 100%;
}
.header_right_set {
  margin-right: 10px;
}
.header_right {
  margin-left: auto;
  margin-right: 10px;
}
.user_container {
  box-sizing: border-box;
  background: url("@/assets/beijing.png") no-repeat;
  background-color: rgb(238, 238, 238);
  background-size: 100%;
  overflow: hidden;
  padding-bottom: 1rem;
  min-height: 100vh;
}
.user_header {
  margin-left: 15px;
  width: 100%;
  padding-left: 0.3rem;
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.header_img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}
.header_action {
  text-align: left;
  margin-left: 10px;
  color: #fff;
  font-size: 15px;
}
</style>
