<template>
  <div class="index" style="background-color: rgb(232, 232, 232); height: 100%;min-height: 100vh;padding-bottom: 10px;">
    <van-nav-bar fixed placeholder @click-left="onClickLeft" title="已咨询" left-arrow />
    <div>
    <div class="cards">
      <div class="msg">
        <span>咨询信息</span>
      </div>
      <div style="display: flex">
        <div class="imgs">
          <van-image
            width="100px"
            height="100px"
            fit="cover"
            :src="details.avatar"
          />
        </div>
        <div class="info">
          <div style="display: flex">
            <div>
              <span style="font-size: 16px; font-weight: 400">{{
                details.name
              }}</span>
            </div>
            <div>
              <img
                v-if="details.isShowQualificationIcon === true"
                style="width: 57px; height: 20px; margin: 3px 0 0 7px"
                src="@/assets/renzhen14.png"
              />
            </div>
          </div>
          <div style="font-size: 12px; margin: 5px 0 5px 0; text-align: left">
            <span>{{ details.introduce }}</span>
          </div>

          <div style="font-size: 12px; margin: 5px 0 5px 0; text-align: left">
            <span class="tag">{{ details.consultantTypes }}</span>
          </div>
          <div style="display: flex; margin: 7px 0 7px 0">
            <div style="display: flex; margin-left: auto">
              <div>
                <van-tag plain type="warning">中铁建员工可享优惠</van-tag>
              </div>
              <div>
                <van-tag plain type="warning" style="margin-left: 3px"
                  >首次优惠</van-tag
                >
              </div>
            </div>
            <div class="mony" style="margin-right: 11px">
              ¥<span style="font-size: 18px">{{ details.normalPrice }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="time">
        <div style="text-align: left; width: 30%"><span>预约时间</span></div>
        <div style="text-align: right; width: 70%">
          <span class="times"
            >{{ details.reservationDay }} {{ details.reservationStartTime }}-{{
              details.reservationEndTime
            }}</span
          >
        </div>
      </div>
    </div>
    <div class="personInfo">
      <div class="msg">
        <span>来访信息</span>
      </div>
      <div>
        <div class="flexinfo">
          <div class="leftText" style="padding-left: 11px">
            <span>来访人姓名</span>
          </div>
          <div style="padding-right: 11px">
            <span>{{ details.comingName }}</span>
          </div>
        </div>
        <div class="flexinfo">
          <div class="leftText" style="padding-left: 11px">
            <span>来访人性别</span>
          </div>
          <div style="padding-right: 11px">
            <span v-if="details.comingGender === 1">男</span>
            <span v-else>女</span>
          </div>
        </div>
        <div class="flexinfo">
          <div class="leftText" style="padding-left: 11px">
            <span>联系方式</span>
          </div>
          <div style="padding-right: 11px">
            <span>{{ details.comingPhone }}</span>
          </div>
        </div>
        <div class="flexinfo">
          <div class="leftText" style="padding-left: 11px">
            <span>咨询方向</span>
          </div>
          <div style="padding-right: 11px">
            <span>{{ details.consultType }}</span>
          </div>
        </div>
        <div class="time">
          <div
            class="leftText"
            style="text-align: left; width: 55%; padding-left: 11px"
          >
            <span>备注</span>
          </div>
          <div style="text-align: right; width: 45%; padding-right: 11px">
            <span class="times"
              >{{details.note}}</span
            >
          </div>
        </div>
        <div class="flexinfo" style="padding-bottom: 15px">
          <div class="leftText" style="padding-left: 11px">
            <span>微信二维码</span>
          </div>
          <div style="padding-right: 11px">
            <div class="imgs">
              <van-image
                width="64px"
                height="64px"
                fit="cover"
                :src="details.wechatQr"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="personInfo">
      <div class="msg">
        <span>价格明细</span>
      </div>
      <div>
        <div class="flexinfo">
          <div class="leftText" style="padding-left: 11px">
            <span>订单总价</span>
          </div>
          <div style="padding-right: 11px">
            <span>¥{{ details.totalPrice }}</span>
          </div>
        </div>
        <div class="flexinfo">
          <div class="leftText" style="padding-left: 11px">
            <span>订单服务次数</span>
          </div>
          <div style="padding-right: 11px">
            <span>1次</span>
          </div>
        </div>
        <div class="flexinfo">
          <div class="leftText" style="padding-left: 11px">
            <span>优惠次数</span>
          </div>
          <div style="padding-right: 11px">
            <span
              >1次 <img style="width: 10px" src="@/assets/you.png" alt=""
            /></span>
          </div>
        </div>
        <div class="flexinfo">
          <div class="leftText" style="padding-left: 11px">
            <span>合计</span>
          </div>
          <div style="padding-right: 11px">
            <span>¥{{ details.totalPrice }}</span>
          </div>
        </div>
        <div class="flexinfo">
          <div class="leftText" style="padding-left: 11px">
            <span>订单编号</span>
          </div>
          <div style="padding-right: 11px">
            <span>{{ details.id }}</span>
          </div>
        </div>
        <div class="flexinfo">
          <div class="leftText" style="padding-left: 11px">
            <span>付款时间</span>
          </div>
          <div style="padding-right: 11px">
            <span>{{details.payTime}}</span>
          </div>
        </div>
        <div class="flexinfo">
          <div class="leftText" style="padding-left: 11px">
            <span>确认时间</span>
          </div>
          <div style="padding-right: 11px">
            <span>{{details.comfirmTime}}</span>
          </div>
        </div>
        <div class="flexinfo" style="padding-bottom: 15px;">
          <div class="leftText" style="padding-left: 11px">
            <span>确认咨询时间</span>
          </div>
          <div style="padding-right: 11px">
            <span>{{details.signOutTime}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="btn">
      <van-button @click="uplodeFeedback" type="primary" style="width: 95%" size="normal"
        >上传反馈</van-button
      >
    </div>
    </div>
  </div>
</template>
<script>
import { Toast } from 'vant'
import { counselorOrderDetail } from '@/api/counselor'
export default {
  data () {
    return {
      orderId: '',
      details: ''
    }
  },
  created () {
    Toast.loading({
      message: '加载中...',
      forbidClick: true
    })
    this.orderId = this.$route.query.id
    this.detail()
  },
  methods: {
    detail () {
      counselorOrderDetail(this.orderId).then((res) => {
        this.details = res.data.data
      })
    },
    uplodeFeedback () {
      this.$router.push(`/uploadFeedback?id=${this.details.id}`)
    },
    onClickLeft () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
:deep{
  .van-button--warning {
    color: rgb(255, 173, 63);
    height: 29px;
    background-color: #fff;
    border-color: rgb(255, 173, 63);
    border-radius: 29px;
    margin: 0 10px 10px 0;
  }
  .van-rate__icon {
    margin-left: 18px;
  }
  .van-nav-bar__title {
    font-weight: bold;
}
.van-popup--center {
    text-align: center;
}
.van-popup--bottom {
    text-align: center;
}
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160%;
  width: 100%;
}
.btn{
  text-align: center;
}
.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
:deep {
  .van-popup--center {
    border-radius: 10px;
    width: 90%;
  }
  .van-button--primary {
    color: #fff;
    width: 82px;
    height: 40px;
    margin-bottom: 10px;
    background-color: rgb(43, 84, 163);
    border-radius: 5px;
    border: 1px solid rgb(43, 84, 163);
  }
  .van-tag--warning.van-tag--plain {
    color: rgb(255, 173, 63);
    border-radius: 5px;
    font-size: 11px;
  }
  .van-button--info {
    color: #fff;
    height: 29px;
    background-color: rgb(43, 84, 163);
    border-color: rgb(43, 84, 163);
    border-radius: 29px;
    margin: 0 10px 10px 0;
    width: 83px;
  }
}
.succee {
  background-color: #fff;
  width: 95%;
}
.personInfo {
  width: 95%;
  margin: auto;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  font-size: 14px;
  .leftText {
    color: rgb(104, 104, 104);
    font-weight: 400;
  }
  .flexinfo {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .time {
    padding-top: 5px;
    display: flex;
    color: rgb(104, 104, 104);
  }
}
.msg {
  font-size: 17px;
  font-weight: 600;
  text-align: left;
  padding: 9px 0 0 11px;
}
.cards {
  width: 95%;
  margin: auto;
  margin-top: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  .imgs {
    margin-left: 13px;
    margin-top: 5px;
  }
  .info {
    font-size: 13px;
    margin: 5px 0 7px 7px;
    text-align: left;
    width: 100%;
  }
  .mony {
    flex: 1;
    text-align: right;
    margin-top: 6px;
    color: rgb(1, 81, 166);
  }
  .time {
    display: flex;
    font-size: 16px;
    padding: 16px 15px 10px 15px;
    color: rgb(104, 104, 104);
    .times {
      color: rgb(43, 84, 163);
    }
  }
}
</style>
