import request from '@/utils/request'
// 咨询师
// 我的信息统计
export function counselorGetMyInfo () {
  return request({
    url: '/api/counselor/getCount',
    method: 'get'
  })
}
// 分页查询订单
export function counselorOrderPage (data) {
  return request({
    url: '/api/counselorOrder/page',
    method: 'post',
    data
  })
}
// 订单详情
export function counselorOrderDetail (orderId) {
  return request({
    url: `/api/counselorOrder/detail/${orderId}`,
    method: 'get'
  })
}
// 填写反馈
export function counselorOrderFeedBack (data) {
  return request({
    url: '/api/counselorOrder/feedBack',
    method: 'post',
    data
  })
}
// 获得订单反馈
export function counselorOrderGetFeedBack (orderId) {
  return request({
    url: `/api/counselorOrder/getFeedBack/${orderId}`,
    method: 'get'
  })
}
// 修改性别
export function changeGender (gender) {
  return request({
    url: `/api/counselor/changeGender/${gender}`,
    method: 'get'
  })
}
// 修改微信号
export function changeWeChat (wechat) {
  return request({
    url: `/api/counselor/changeWeChat/${wechat}`,
    method: 'get'
  })
}
// 获取信息
export function counselorGetInfo () {
  return request({
    url: '/api/counselor/getInfo',
    method: 'get'
  })
}
// 修改名称
export function changeName (name) {
  return request({
    url: `/api/counselor/changeName/${name}`,
    method: 'get'
  })
}
