<template>
  <div class="index">
    <van-nav-bar fixed placeholder title="我的预约" left-arrow />
    <van-tabs v-model="active">
      <van-tab title="已确认">
         <div v-if="active === 0" class="indexH">
          <confirmS></confirmS>
        </div>
      </van-tab>
      <van-tab title="已咨询">
        <div v-if="active === 1" class="indexH">
          <consultS></consultS>
        </div>
      </van-tab>
      <van-tab title="已反馈">
        <div v-if="active ===2" class="indexH">
          <feedbackS></feedbackS>
        </div>
      </van-tab>
      <van-tab title="已取消">
        <div v-if="active === 3" class="indexH">
          <cancelS></cancelS>
        </div>
      </van-tab>
    </van-tabs>
     <van-tabbar placeholder  v-model="actives">
      <van-tabbar-item icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item @click="my" icon="manager-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import cancelS from './components/cancelS.vue'
import feedbackS from './components/feedbackS.vue'
import consultS from './components/consultS.vue'
import confirmS from './components/confirmS.vue'
export default {
  components: {
    confirmS,
    consultS,
    feedbackS,
    cancelS
  },
  data () {
    return {
      active: 0,
      actives: 0
    }
  },
  created () {
  },
  methods: {
    my () {
      this.$router.push('/myInfoS')
    },
    onClickLeft () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
:deep {
  .van-nav-bar__left {
    display: none;
  }
  .van-tabs--line .van-tabs__wrap {
    top: 45px;
    position: fixed;
    height: 44px;
    width: 100%;
    z-index: 1000;
    margin-bottom: 40px;
}
  .van-tab--active {
    color: rgb(43, 84, 163);
    font-size: 16px;
    font-weight: 500;
  }
  .van-tabs__line {
    background-color: rgb(43, 84, 163);
  }
  .van-nav-bar__title {
    font-weight: bold;
  }
  .van-button--info {
    color: #fff;
    background-color: rgb(43, 84, 163);
    border-color: rgb(43, 84, 163);
    border-radius: 29px;
    height: 29px;
  }
}
.index {
  margin: auto;
  background-color: rgb(232, 232, 232);
  height: 100%;
  width: 100%;
  min-height: 100vh;
  .indexH{
    margin-top: 50px;
  }
}
</style>
