import axios from 'axios'
import { Notify } from 'vant'
import router from '@/router'
console.log(this)
const service = axios.create({
  withCredentials: true,
  timeout: 10000
})
service.interceptors.request.use(function (config) {
  const token = window.localStorage.getItem('access_token')
  config.headers = {
    Authorization: 'Basic c2FiZXI6c2FiZXJfc2VjcmV0',
    'Blade-Auth': `bearer\xa0${token}`
  }
  return config
}, function (error) {
  const re = JSON.parse(error.request.response)
  Notify({ type: 'danger', message: re.msg })
  // 对请求错误的处理
  return Promise.reject(error)
})
service.interceptors.response.use(function (response) {
  // 返回任何 2xx 的状态码都会触发这个函数，对响应数据进行加工处理。
  return response
}, function (error) {
  if (error.response.data.code === 401) {
    Notify({ type: 'danger', message: '登录已过期,请重新登录' })
    window.localStorage.clear()
    router.push('/')
  } else {
    const re = JSON.parse(error.request.response)
    Notify({ type: 'danger', message: re.msg })
  }

  // 非以 2xx 开头的状态码都会触发，响应错误的处理
  return Promise.reject(error)
})
export default service
