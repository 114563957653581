<template>
  <div class="index">
    <van-nav-bar @click-left="onClickLeft" title="微信号" left-arrow />
    <div class="input">
      <van-field
        v-model="message"
        rows="1"
        autosize
        type="textarea"
        clearable
        placeholder="请输入微信号"
      />
    </div>
    <div class="btn">
      <van-button @click="save" style="width: 90%" type="info">保存</van-button>
    </div>
  </div>
</template>

<script>
import { changeWeChat } from '@/api/counselor'
import { consultantChangeWeChat } from '@/api/user'
import { Toast, Dialog } from 'vant'
export default {
  data () {
    return {
      message: ''
    }
  },
  methods: {
    save () {
      console.log(this.$route.query.id)
      if (this.message === '') {
        Toast.fail('请输入微信号')
      } else {
        Dialog.confirm({
          title: '微信号',
          message: '确认修改微信号码吗'
        })
          .then(() => {
            if (this.$route.query.id === undefined) {
              consultantChangeWeChat(this.message).then((res) => {
                Toast.success('修改成功')
                this.$router.go(-1)
              })
            } else {
              changeWeChat(this.message).then((res) => {
                Toast.success('修改成功')
                this.$router.go(-1)
              })
            }
          })
          .catch(() => {
            // on cancel
          })
      }
    },
    onClickLeft () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
:deep {
  .van-nav-bar__title {
    font-weight: bold;
  }
  .van-button--info {
    color: #fff;
    background-color: rgb(43, 84, 163);
    border-color: rgb(43, 84, 163);
    border-radius: 9px;
  }
  .van-cell {
    border-radius: 10px;
    width: 95%;
    margin-left: 10px;
    margin-left: 10px;
  }
}
.index {
  margin: auto;
  background-color: rgb(238, 238, 238);
  height: 100vh;
  width: 100%;
  .input {
    margin-top: 10px;
  }
  .btn {
    text-align: center;
    margin-bottom: 10px;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
  }
}
</style>
