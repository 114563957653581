<template>
  <!-- 已咨询 -->
  <div>
    <van-empty
      style="background-color: #fff"
      v-if="total === 0"
      image="error"
      description="暂无数据"
    />
    <div
      v-if="total > 0"
      class="index"
      style="background-color: rgb(232, 232, 232)"
    >
      <div>
        <van-list
        :immediate-check='false'
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          offset="10"
        >
          <div v-for="(item, index) in list" :key="index">
            <div class="cards">
              <div @click="consulte(item)" class="timeInfos">
                <div class="msg">
                  <span>订单编号：</span
                  ><span style="color: rgb(118, 118, 118)">{{ item.id }}</span>
                </div>
                <div>
                  <span style="color: rgb(43, 84, 163)">已咨询</span>
                </div>
              </div>
              <div @click="consulte(item)" style="display: flex">
                <div class="imgs">
                  <van-image
                    width="100px"
                    height="100px"
                    fit="cover"
                    :src="item.avatar"
                  />
                </div>
                <div class="info">
                  <div style="display: flex">
                    <div>
                      <span style="font-size: 16px; font-weight: 400">{{
                        item.name
                      }}</span>
                    </div>
                    <div>
                      <img
                        v-if="item.isShowQualificationIcon === true"
                        style="width: 57px; height: 20px; margin: 3px 0 0 7px"
                        src="@/assets/renzhen14.png"
                      />
                    </div>
                  </div>
                  <div
                    style="
                      font-size: 12px;
                      margin: 5px 0 5px 0;
                      text-align: left;
                    "
                  >
                    <span>{{ item.introduce }}</span>
                  </div>

                  <div
                    style="
                      font-size: 12px;
                      margin: 5px 0 5px 0;
                      text-align: left;
                    "
                  >
                    <span class="tag">{{ item.consultType }}</span>
                  </div>
                  <div style="display: flex; margin: 7px 0 7px 0">
                    <div style="display: flex; margin-left: auto">
                      <div>
                        <van-tag plain type="warning"
                          >中铁建员工可享优惠</van-tag
                        >
                      </div>
                      <div>
                        <van-tag plain type="warning" style="margin-left: 3px"
                          >首次优惠</van-tag
                        >
                      </div>
                    </div>
                    <div class="mony" style="margin-right: 11px">
                      ¥<span style="font-size: 18px">{{
                        item.totalPrice
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div @click="consulte(item)" class="userInfo">
            <div style="padding: 7px 9px 15px 9px;" class="flex">
              <div class="left">实付款:</div>
              <div class="right">¥{{ item.totalPrice }}</div>
            </div>
            <div style="padding: 0 9px 10px 9px;" class="flex">
              <div class="left">咨询时间:</div>
              <div class="right">
                {{ item.reservationDay }} {{ item.reservationStartTime }}-{{
                  item.reservationEndTime
                }}
              </div>
            </div>
          </div>
              <div
                style="margin:15px 11px 15px 0; text-align: right;"
              >
                <van-button @click="again(item)" type="info"
                  >再次预约</van-button
                >
              </div>
            </div>
            <div v-if="item.serviceSatisfaction === 0" class="cards">
              <div style="padding-top: 15px">
                <span style="font-size: 16px; margin-left: 12px"
                  >对咨询师的服务满意吗?</span
                >
              </div>
              <van-divider />
              <div style="display: flex; justify-content: space-between">
                <div>
                  <van-rate
                    v-model="item.value"
                    :size="30"
                    color="rgb(255, 173, 63)"
                    void-icon="star"
                    void-color="#eee"
                    @change="change"
                  />
                </div>
                <div style="margin-right: 10px; margin-top: 5px">
                  <span style="font-size: 14px; color: rgb(255, 173, 63)">{{
                    rate
                  }}</span>
                </div>
              </div>
              <div style="text-align: right; margin-top: 20px">
                <van-button @click="ok(item)" type="warning">选好了</van-button>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>
<script>
import { Toast, Dialog } from 'vant'
import { consultantOrderPage, consultantOrderScore } from '@/api/user'
export default {
  data () {
    return {
      list: [],
      loading: false,
      finished: false,
      current: 1,
      size: 10,
      value: 3,
      rate: '一般',
      total: ''
    }
  },
  created () {
    Toast.loading({
      message: '加载中...',
      forbidClick: true
    })
    this.List()
  },
  methods: {
    again (item) {
      this.$router.push(`/predetails?id=${item.counselorId}`)
    },
    ok (item) {
      Dialog.confirm({
        title: '评分',
        message: '确定评分吗？'
      })
        .then(() => {
          consultantOrderScore(item.id, item.value).then((res) => {
            Toast.success('评价成功')
            this.current = 1
            this.list = []
            this.List()
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    List () {
      consultantOrderPage({
        current: this.current,
        size: this.size,
        status: 5
      }).then((res) => {
        res.data.data.records.forEach((element) => {
          element.value = 3
        })
        this.list = this.list.concat(res.data.data.records)
        this.total = res.data.data.total
        if (this.list.length === res.data.data.total) {
          this.loading = true
          this.finished = true
        }
      })
    },
    onLoad () {
      this.current++
      this.List()
    },
    consulte (item) {
      this.$router.push(`/consultedS?id=${item.id}`)
    },
    change (value) {
      switch (value) {
        case 1:
          this.rate = '非常不满意'
          break
        case 2:
          this.rate = '不满意'
          break
        case 3:
          this.rate = '一般'
          break
        case 4:
          this.rate = '满意'
          break
        case 5:
          this.rate = '非常满意'
          break
      }
    },
    onClickLeft () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
:deep {
  .van-button--warning {
    color: rgb(255, 173, 63);
    height: 29px;
    background-color: #fff;
    border-color: rgb(255, 173, 63);
    border-radius: 29px;
    margin: 0 10px 10px 0;
  }
  .van-rate__icon {
    margin-left: 18px;
  }
  .van-nav-bar__title {
    font-weight: bold;
  }
  .van-popup--center {
    border-radius: 10px;
    width: 90%;
  }
  .van-tag--warning.van-tag--plain {
    color: rgb(255, 173, 63);
    border-radius: 5px;
    font-size: 11px;
  }
  .van-button--default {
    color: rgb(43, 84, 163);
    height: 29px;
    background-color: #fff;
    border-color: rgb(43, 84, 163);
    border-radius: 29px;
    margin: 0 10px 10px 0;
  }
}
.userInfo {
  border-radius: 10px;
  width: 95%;
  margin: auto;
  margin-top: 15px;
  background-color: rgb(248, 248, 248);
  .flex {
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    .left {
      color: rgb(87, 87, 87);
    }
  }
}
.timeInfos {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  padding: 15px 15px 10px 9px;
}
.times {
  font-size: 16px;
  color: rgb(43, 84, 163);
  font-weight: bold;
}
.msg {
  font-size: 14px;
  text-align: left;
}
.index {
  min-height: 92vh;
}
.cards {
  width: 95%;
  margin: auto;
  margin-top: 10px;
  border-radius: 10px;
  padding-bottom: 5px;
  background-color: #fff;
  .imgs {
    margin-left: 13px;
    margin-top: 5px;
  }
  .info {
    font-size: 13px;
    margin: 5px 0 7px 7px;
    text-align: left;
    width: 100%;
  }
  .mony {
    flex: 1;
    text-align: right;
    margin-top: 6px;
    color: rgb(1, 81, 166);
  }
  .time {
    display: flex;
    font-size: 16px;
    padding: 16px 15px 10px 15px;
    color: rgb(104, 104, 104);
    .times {
      color: rgb(43, 84, 163);
      font-weight: normal;
    }
  }
}
</style>
