<template>
  <!-- 已咨询 -->
  <div
    style="
      height: 100%;
      background-color: rgb(232, 232, 232);
      min-height: 100vh;
    "
  >
    <van-nav-bar
      fixed
      placeholder
      @click-left="onClickLeft"
      title="待咨询"
      left-arrow
    />
    <div>
      <van-empty
        style="background-color: #fff; height: 100vh"
        v-if="total === 0"
        image="error"
        description="暂无数据"
      />
      <div
        v-if="total > 0"
        class="index"
        style="background-color: rgb(232, 232, 232)"
      >
        <van-list
        :immediate-check='false'
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          offset="10"
        >
          <div
            @click="cancel(item)"
            v-for="(item, index) in list"
            :key="index"
            class="cards"
          >
            <div class="timeInfos">
              <div class="msg">
                <span>订单编号：</span
                ><span style="color: rgb(118, 118, 118)">{{ item.id }}</span>
              </div>
              <div style="margin-top: 10px">
                <span style="color: rgb(43, 84, 163)">已确认</span>
              </div>
            </div>
            <div class="userInfo">
              <div class="flex">
                <div class="left">咨询时间:</div>
                <div class="right">
                  {{ item.reservationDay }} {{ item.reservationStartTime }}-{{
                    item.reservationEndTime
                  }}
                </div>
              </div>
              <div class="flex">
                <div class="left">咨询方向:</div>
                <div class="right">{{ item.consultType }}</div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>
<script>
import { counselorOrderPage } from '@/api/counselor'
import { Toast } from 'vant'
export default {
  data () {
    return {
      current: 1,
      size: 10,
      id: '',
      total: '',
      list: [],
      loading: false,
      finished: false
    }
  },
  created () {
    Toast.loading({
      message: '加载中...',
      forbidClick: true
    })
    this.List()
  },
  methods: {
    List () {
      counselorOrderPage({
        current: this.current,
        size: this.size,
        status: 2
      }).then((res) => {
        this.list = this.list.concat(res.data.data.records)
        this.total = res.data.data.total
        if (this.list.length === res.data.data.total) {
          this.loading = true
          this.finished = true
        }
      })
    },
    onLoad () {
      this.current++
      this.List()
    },
    cancel (item) {
      this.$router.push(`/counseloConfirmed?id=${item.id}`)
    },
    onClickLeft () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.userInfo {
  border-radius: 10px;
  width: 95%;
  margin: auto;
  margin-top: 15px;
  background-color: rgb(248, 248, 248);
  .flex {
    padding: 10px 9px 10px 9px;
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    .left {
      color: rgb(87, 87, 87);
    }
  }
}
.timeInfos {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  padding: 9px 10px 5px 5px;
}
.times {
  font-size: 16px;
  color: rgb(43, 84, 163);
  font-weight: bold;
}
.msg {
  font-size: 14px;
  text-align: left;
  padding: 9px 0 0 11px;
}
.index {
  margin-top: 10px;
}
.cards {

  width: 95%;
  margin: auto;
  margin-top: 10px;
  border-radius: 10px;
  padding-bottom: 15px;
  background-color: #fff;
  .imgs {
    margin-left: 13px;
    margin-top: 5px;
  }
  .info {
    font-size: 13px;
    margin: 5px 0 7px 7px;
    text-align: left;
  }
  .mony {
    color: rgb(1, 81, 166);
    width: 20%;
    float: right;
    margin: 7px 0 0 0;
  }
  .time {
    display: flex;
    font-size: 16px;
    padding: 16px 15px 10px 15px;
    color: rgb(104, 104, 104);
    .times {
      color: rgb(43, 84, 163);
      font-weight: normal;
    }
  }
}
</style>
