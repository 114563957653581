<template>
  <div class="index">
    <van-nav-bar @click-left="onClickLeft" title="延期预约" left-arrow />
    <div class="infoMeg">
      <div class="info_div">
        <div>
          <span class="info">填写信息</span>
        </div>
        <div class="info2">
          <span>个人信息内容平台将严格保密，仅用于本次咨询服务</span>
        </div>
        <div style="margin-top: 15px">
          <van-field
          required
            v-model="message"
            rows="6"
            autosize
            type="textarea"
            placeholder="请输入延期原因"
          />
        </div>
      </div>
    </div>
    <div class="shadow">
      <div>
        <van-cell
          @click="yuyue"
           style="
              background-color: #fff !important;
              margin-top: 10px;
              font-size: 15px;
              color: rgb(1, 81, 166);
            "
          :title="titles"
          icon="passed"
          is-link
        />
        <div class="btns">
          <van-button
            @click="quxiao"
            style="margin-right: 15px"
            round
            type="info"
            >取消延期</van-button
          >
          <van-button
            style="margin-left: 15px"
            round
            type="info"
            @click="confirm"
            >确认延期</van-button
          >
        </div>
      </div>
    </div>
    <!-- 预约时间弹窗 -->
    <van-action-sheet v-model="show" title="选择延期预约时间">
      <van-tree-select
        :items="items"
        :active-id.sync="activeId"
        :main-active-index.sync="activeIndex"
        @click-nav="clickNav"
        @click-item="clickItem"
      />
    </van-action-sheet>
    <!-- 预约支付弹窗 -->
    <van-popup v-model="shows">
      <div class="succee">
        <div style="margin-top: 20px">
          <img
            style="width: 40px"
            src="@/assets/succeed.png"
            alt=""
            srcset=""
          />
        </div>
        <div>延期成功</div>
        <hr />
        <div style="margin-top: 20px">您已完成延期操作,请等待咨询师确认...</div>
        <div style="margin-top: 20px">
          <van-button
            @click="Ikonw"
            style="width: 40%; height: 30px; border-radius: 10px"
            type="primary"
            size="normal"
            >我知道了</van-button
          >
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { consultantChangeReserveTime } from '@/api/user'
import { getReserTime } from '@/api/appointment'
import { Dialog, Toast } from 'vant'
export default {
  data () {
    return {
      shows: false,
      message: '',
      show: false,
      items: [],
      activeId: 0,
      activeIndex: 0,
      orderId: '',
      one: '',
      two: '',
      titles: '选择延期预约时间',
      ids: '',
      idT: ''

    }
  },
  created () {
    this.idT = this.$route.query.ids
    this.orderId = this.$route.query.id
    getReserTime(this.orderId).then((res) => {
      this.one = res.data.data[0].day
      res.data.data.forEach((element) => {
        element.text = element.day
        element.children.forEach((elements) => {
          elements.text = elements.time
        })
      })
      this.items = res.data.data
    })
  },
  methods: {
    clickNav (data) {
      this.one = this.items[data].day
      this.two = this.items[data].day + '\xa0' + this.items[data].week
    },
    clickItem (data) {
      Dialog.confirm({
        title: '确认预约时间',
        message: '确定选择这个时间预约咨询师了吗?'
      })
        .then(() => {
          this.two = this.two + '\xa0' + data.text
          this.titles = this.one + '\xa0' + data.text + '\xa0' + '已选延期预约时间'
          this.ids = data.id
          this.show = false
          console.log(data)
        })
        .catch(() => {
          this.activeId = 0
        })
    },
    Ikonw () {
      this.show = false
      this.$router.replace('/reservation')
    },
    confirm () {
      if (this.message === '') {
        Toast.fail('请输入延期原因')
      } else if (this.ids === '') {
        Toast.fail('请选择延期预约时间')
      } else {
        Dialog.confirm({
          title: '确认延期预约',
          message: '确定选择这个时间延期预约咨询师了吗?'
        })
          .then(() => {
            consultantChangeReserveTime(this.idT, this.ids).then(res => {
              this.shows = true
            })
          })
          .catch(() => {
          })
      }
    },
    yuyue () {
      this.show = true
    },
    quxiao () {
      this.$router.go(-1)
    },
    onClickLeft () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
:deep {
  .van-popup--center {
    text-align: center;
    border-radius: 10px;
    width: 90%;
  }
  .van-button--primary {
    color: #fff;
    height: 40px;
    margin-bottom: 10px;
    background-color: rgb(43, 84, 163);
    border-radius: 5px;
    border: 1px solid rgb(43, 84, 163);
  }
  .van-sidebar-item--select::before {
    background-color: rgb(1, 81, 166);
  }
  .van-tree-select__item--active {
    color: rgb(1, 81, 166);
  }
  .van-nav-bar__title {
    font-weight: bold;
  }
  .van-sidebar-item--select::before {
    background-color: rgb(1, 81, 166);
  }
  .van-tree-select__item--active {
    color: rgb(1, 81, 166);
  }
  .van-tree-select__item {
    text-align: left;
  }
  .van-action-sheet__header {
    font-weight: 600;
    text-align: left;
    margin-left: 15px;
  }
  .van-cell {
    background-color: rgb(246, 246, 246) !important;
    border-radius: 10px;
    padding: 5px 16px;
  }
  .van-button--info {
    height: 37px;
    width: 120px;
    margin-bottom: 10px;
    background-color: rgb(233, 233, 233);
    border: 1px solid rgb(233, 233, 233);
    color: #333;
    font-weight: bold;
  }
}
.btns {
  margin-top: 20px;
}
.index {
  .succee {
  background-color: #fff;
  width: 95%;
}
  .shadow {
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    border-radius: 14px;
    background-color: #fff;
    box-shadow: 0px -5px 10px 0px rgb(233, 233, 233);
  }
  .infoMeg {
    margin: 10px 10px 10px 10px;
    .info_div {
      text-align: left;
      .info {
        font-weight: bold;
        font-size: 15px;
      }
      .info2 {
        font-size: 12px;
        color: rgb(86, 86, 86);
        margin-top: 5px;
      }
    }
    .title {
      margin: 5px 0 5px 0;
      color: rgb(86, 86, 86);
      font-size: 14px;
    }
  }
}
</style>
