import request from '@/utils/request'
// 可预约时间
export function getReserTime (id) {
  return request({
    url: `/api/consultantOrder/getReserTime/${id}`,
    method: 'get'
  })
}
// 绑卡历史
export function getConsultType (id) {
  return request({
    url: `/api/consultantOrder/getConsultType/${id}`,
    method: 'get'
  })
}
// 上传图片
export const putfile = (file) => {
  const formData = new FormData()
  formData.append('file', file.file)
  console.log(file.file)
  return request({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    url: '/api/blade-resource/oss/endpoint/put-file',
    method: 'post',
    data: formData
  })
}
// 下单
export function consultantOrder (data) {
  return request({
    url: '/api/consultantOrder/order',
    method: 'post',
    data
  })
}
// export function putfile (file) {
//   return request({
//     url: '/api/blade-resource/oss/endpoint/put-file',
//     method: 'post',
//     headers: {
//       'Content-Type': 'multipart/form-data'
//     },
//     data: {
//       file
//     }
//   })
// }
