import { render, staticRenderFns } from "./canceledS.vue?vue&type=template&id=6f4e718c&scoped=true&"
import script from "./canceledS.vue?vue&type=script&lang=js&"
export * from "./canceledS.vue?vue&type=script&lang=js&"
import style0 from "./canceledS.vue?vue&type=style&index=0&id=6f4e718c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f4e718c",
  null
  
)

export default component.exports