import { render, staticRenderFns } from "./homeS.vue?vue&type=template&id=0208cd9f&scoped=true&"
import script from "./homeS.vue?vue&type=script&lang=js&"
export * from "./homeS.vue?vue&type=script&lang=js&"
import style0 from "./homeS.vue?vue&type=style&index=0&id=0208cd9f&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0208cd9f",
  null
  
)

export default component.exports