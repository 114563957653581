<template>
  <div class="index">
    <van-nav-bar @click-left="onClickLeft" title="个人信息" left-arrow />
    <div class="user_card">
      <div class="flex">
        <div style="margin-top: 15px">
          <span>头像</span>
        </div>
        <div>
          <van-image
            round
            width="48px"
            height="48px"
            fit="cover"
            :src="avatar"
          />
        </div>
      </div>
      <hr />
      <div class="flex">
        <div class="left">
          <span>姓名</span>
        </div>
        <div class="right">
          <span
          v-if="name === ''"
            >暂无 </span>
          <span
          v-else
            >{{name}} </span>
        </div>
      </div>
      <!-- <hr />
      <div class="flex">
        <div class="left">
          <span>性别</span>
        </div>
        <div @click="genderSel" class="right">
          <span>
            <span v-if="gender === 1">男</span>
            <span v-else>女</span>
            <img style="width: 13px" src="@/assets/you.png" alt=""
          /></span>
        </div>
      </div> -->
      <hr />
      <div class="flex">
        <div class="left">
          <span>手机号</span>
        </div>
        <div class="right">
          <span>+86 {{ phone }}</span>
        </div>
      </div>
      <hr />
      <div class="flex">
        <div class="left">
          <span>身份</span>
        </div>
        <div class="right">
          <span>咨询师</span>
        </div>
      </div>
      <!-- <div @click="wxID" class="flex">
        <div class="left">
          <span>微信号</span>
        </div>
        <div class="right">
          <span v-if="wechat === ''"
            >暂无 <img style="width: 13px" src="@/assets/you.png" alt=""
          /></span>
          <span v-else
            >{{ wechat }}
            <img style="width: 13px" src="@/assets/you.png" alt=""
          /></span>
        </div>
      </div> -->
      <hr />
    </div>
    <!-- 性别选择 -->
    <van-action-sheet
      cancel-text="取消"
      v-model="show"
      :actions="actions"
      @select="onSelect"
    />
  </div>
</template>

<script>
import { changeGender, counselorGetInfo } from '@/api/counselor'
import { Dialog, Toast } from 'vant'
export default {
  data () {
    return {
      show: false,
      gender: '',
      actions: [{ name: '男' }, { name: '女' }],
      avatar: '',
      phone: '',
      wechat: '',
      name: ''
    }
  },
  created () {
    counselorGetInfo().then((res) => {
      this.wechat = res.data.data.wechat
      this.gender = res.data.data.gender
      this.name = res.data.data.name
    })
    this.avatar = window.localStorage.getItem('avatar')
    this.phone = window.localStorage.getItem('phone')
  },
  methods: {
    names () {
      this.$router.push('/names?id=2')
    },
    onSelect (item) {
      Dialog.confirm({
        title: '修改性别',
        message: '确认修改性别吗?'
      })
        .then(() => {
          console.log(item.name)
          if (item.name === '男') {
            this.genders = 1
          } else {
            this.genders = 2
          }
          changeGender(this.genders).then((res) => {
            this.gender = this.genders
            Toast.success('修改成功')
            this.show = false
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    wxID () {
      this.$router.push('/WechatID?id=2')
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    genderSel () {
      this.show = true
    }
  }
}
</script>

<style lang="scss" scoped>
:deep {
  .van-nav-bar__title {
    font-weight: bold;
  }
}
.index {
  background-color: rgb(232, 232, 232);
  height: 100vh;
  .user_card {
    margin: auto;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 7px;
    padding: 16px 16px 10px 16px;
    width: 90%;
    .flex {
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
      padding-top: 5px;
      font-size: 14px;
    }
  }
}
</style>
