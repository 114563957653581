<template>
  <div class="index">
    <van-nav-bar @click-left="onClickLeft" title="更改密码" left-arrow />
    <div class="input">
      <van-field
        @click-right-icon="showPassword1"
        :right-icon="icon1"
        :type="type1"
        v-model="cardNumber"
        autosize
        label="旧密码:"
        clearable
        placeholder="请输入旧密码"
      />
    </div>
    <div class="input">
      <van-field
        @click-right-icon="showPassword2"
        :right-icon="icon2"
        :type="type2"
        v-model="cardPassword"
        autosize
        maxlength="16"
        label="新密码:"
        clearable
        placeholder="请输入新密码"
      />
    </div>
    <div class="input">
      <van-field
        maxlength="16"
        @click-right-icon="showPassword"
        :right-icon="icon3"
        :type="type3"
        v-model="cardPasswordS"
        autosize
        label="确认密码:"
        clearable
        placeholder="请确认密码"
      />
    </div>
    <div style="margin-top: 10px; margin-left: 15px; color: #646566">
      <span>密码由数字和字母组成,且长度要在8-16位之间</span>
    </div>
    <div class="btn">
      <van-button @click="bindinng" style="width: 90%" type="info"
        >修改</van-button
      >
    </div>
  </div>
</template>

<script>
import { changePwd } from '@/api/login'
import { Dialog, Toast } from 'vant'
export default {
  data () {
    return {
      icon1: 'closed-eye',
      type1: 'password',
      icon2: 'closed-eye',
      type2: 'password',
      icon3: 'closed-eye',
      type3: 'password',
      cardNumber: '',
      cardPassword: '',
      cardPasswordS: ''
    }
  },
  methods: {
    showPassword2 () {
      if (this.icon2 === 'closed-eye') {
        this.icon2 = 'eye-o'
        this.type2 = 'text'
      } else {
        this.icon2 = 'closed-eye'
        this.type2 = 'password'
      }
    },
    showPassword1 () {
      if (this.icon1 === 'closed-eye') {
        this.icon1 = 'eye-o'
        this.type1 = 'text'
      } else {
        this.icon1 = 'closed-eye'
        this.type1 = 'password'
      }
    },
    showPassword () {
      if (this.icon3 === 'closed-eye') {
        this.icon3 = 'eye-o'
        this.type3 = 'text'
      } else {
        this.icon3 = 'closed-eye'
        this.type3 = 'password'
      }
    },
    bindinng () {
      this.cardPassword = String(this.cardPassword)
      this.cardPasswordS = String(this.cardPasswordS)
      if (this.cardNumber === '') {
        Toast.fail('请输入旧密码')
      } else if (this.cardPassword === '') {
        Toast.fail('请输入新密码')
      } else if (this.cardPasswordS === '') {
        Toast.fail('请确认密码')
      } else if (this.cardPassword !== this.cardPasswordS) {
        Toast.fail('两次输入的密码不一致')
      } else {
        Dialog.confirm({
          title: '修改密码',
          message: '是否修改密码?'
        })
          .then(() => {
            changePwd({ newPwd: this.cardPasswordS, oldPwd: this.cardNumber }).then(res => {
              Toast.success('密码修改成功')
              this.cardNumber = ''
              this.cardPassword = ''
              this.cardPasswordS = ''
            })
          })
          .catch(() => {
            Toast('已取消')
          })
      }
    },

    onClickLeft () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
:deep {
  .van-nav-bar__title {
    font-weight: bold;
  }
  .van-button--info {
    color: #fff;
    background-color: rgb(43, 84, 163);
    border-color: rgb(43, 84, 163);
    border-radius: 9px;
  }
  .van-cell {
    border-radius: 10px;
    width: 95%;
    margin-left: 10px;
    margin-left: 10px;
  }
}
.index {
  min-height: 100vh;
  margin: auto;
  background-color: rgb(238, 238, 238);
  height: 100%;
  width: 100%;
  .input {
    margin-top: 10px;
  }
  .btn {
    text-align: center;
    margin-bottom: 10px;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
  }
}
</style>
