var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"index"},[_c('van-nav-bar',{attrs:{"title":"个人信息","left-arrow":""},on:{"click-left":_vm.onClickLeft}}),_c('div',{staticClass:"user_card"},[_c('div',{staticClass:"flex"},[_vm._m(0),_c('div',[_c('van-image',{attrs:{"round":"","width":"48px","height":"48px","fit":"cover","src":_vm.avatar}})],1)]),_c('hr'),_c('div',{staticClass:"flex"},[_vm._m(1),_c('div',{staticClass:"right",on:{"click":_vm.names}},[(_vm.name === '')?_c('span',[_vm._v("暂无 "),_c('img',{staticStyle:{"width":"13px"},attrs:{"src":require("@/assets/you.png"),"alt":""}})]):_c('span',[_vm._v(_vm._s(_vm.name)+" "),_c('img',{staticStyle:{"width":"13px"},attrs:{"src":require("@/assets/you.png"),"alt":""}})])])]),_c('hr'),_c('div',{staticClass:"flex"},[_vm._m(2),_c('div',{staticClass:"right",on:{"click":_vm.genderSel}},[_c('span',[(_vm.gender === 1)?_c('span',[_vm._v("男")]):_c('span',[_vm._v("女")]),_c('img',{staticStyle:{"width":"13px"},attrs:{"src":require("@/assets/you.png"),"alt":""}})])])]),_c('hr'),_c('div',{staticClass:"flex"},[_vm._m(3),_c('div',{staticClass:"right"},[_c('span',[_vm._v("+86 "+_vm._s(_vm.phone))])])]),_c('hr'),_c('div',{staticClass:"flex",on:{"click":_vm.wxID}},[_vm._m(4),_c('div',{staticClass:"right"},[_c('span',[_vm._v(_vm._s(_vm.wechat)+" "),_c('img',{staticStyle:{"width":"13px"},attrs:{"src":require("@/assets/you.png"),"alt":""}})])])]),_c('hr'),_c('div',{staticClass:"flex"},[_vm._m(5),_c('div',{staticClass:"right"},[_c('van-image',{attrs:{"width":"64px","height":"64px","fit":"cover","src":_vm.wechat_qr},on:{"click":function($event){return _vm.getImg(_vm.wechat_qr)}}})],1)])]),_c('van-action-sheet',{attrs:{"cancel-text":"取消","actions":_vm.actions},on:{"select":_vm.onSelect},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"15px"}},[_c('span',[_vm._v("头像")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('span',[_vm._v("昵称")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('span',[_vm._v("性别")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('span',[_vm._v("手机号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('span',[_vm._v("微信号")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('span',[_vm._v("微信二维码")])])
}]

export { render, staticRenderFns }