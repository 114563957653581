<template>
  <div class="index" style="background-color: rgb(232, 232, 232)">
    <van-nav-bar
      fixed
      placeholder
      @click-left="onClickLeft"
      title="签到/签退"
      left-arrow
    />
    <div>
      <van-empty
        style="background-color: #fff;margin-top: 10px;min-height:92vh;"
        v-if="total === 0"
        image="error"
        description="暂无数据"
      />
      <div
        v-if="total > 0"
        class="index"
        style="background-color: rgb(232, 232, 232)"
      >
        <van-list
        :immediate-check='false'
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          offset="10"
        >
          <div v-for="(item, index) in list" :key="index" class="cards">
            <div @click="appointmentS(item)" class="timeInfos">
              <div class="msg">
                <span>订单编号：</span
                ><span style="color: rgb(118, 118, 118)">{{ item.id }}</span>
              </div>
              <div>
                <span style="color: rgb(43, 84, 163)">已确认</span>
              </div>
            </div>
            <div @click="appointmentS(item)" style="display: flex">
          <div class="imgs">
            <van-image
              width="100px"
              height="100px"
              fit="cover"
              :src="item.avatar"
            />
          </div>
          <div class="info">
              <div style="display: flex">
                <div>
                  <span style="font-size: 16px; font-weight: 400">{{
                    item.name
                  }}</span>
                </div>
                <div>
                  <img
                    v-if="item.isShowQualificationIcon === true"
                    style="width: 57px; height: 20px; margin: 3px 0 0 7px"
                    src="@/assets/renzhen14.png"
                  />
                </div>
              </div>
            <div style="font-size: 12px; margin: 5px 0 5px 0; text-align: left">
              <span>{{ item.introduce }}</span>
            </div>

            <div style="font-size: 12px; margin: 5px 0 5px 0; text-align: left">
              <span class="tag">{{ item.consultType }}</span>
            </div>
            <div style="display: flex; margin: 7px 0 7px 0">
              <div style="display: flex;margin-left: auto;">
                <div>
                  <van-tag plain type="warning">中铁建员工可享优惠</van-tag>
                </div>
                <div>
                  <van-tag plain type="warning" style="margin-left: 3px;">首次优惠</van-tag>
                </div>
              </div>
              <div class="mony" style="margin-right: 11px;">
                ¥<span style="font-size: 18px">{{ item.totalPrice }}</span>
              </div>
            </div>
          </div>
        </div>
            <div class="userInfo">
              <div  @click="appointmentS(item)" class="flex">
                <div class="left">咨询时间：</div>
                <div class="right">
                  {{ item.reservationDay }} {{ item.reservationStartTime }}-{{
                    item.reservationEndTime
                  }}
                </div>
            </div>
            <div style="margin-top: 20px; text-align: right; margin-right: 5px">
              <van-button v-if="item.signInTime === ''" type="warning" @click="signIn(item)">签到</van-button>
              <van-button v-else type="warning" @click="signOut(item)"
                >签退</van-button
              >
            </div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>
<script>
import { Dialog, Toast } from 'vant'
import { consultantOrderPage, consultantOrderSignOut, consultantOrderSignIn } from '@/api/user'

export default {
  data () {
    return {
      list: [],
      loading: false,
      finished: false,
      show: false,
      message: '',
      current: 1,
      size: 10,
      id: '',
      total: ''
    }
  },
  created () {
    Toast.loading({
      message: '加载中...',
      forbidClick: true
    })
    this.List()
  },
  methods: {
    appointmentS (item) {
      this.$router.push(`/confirmedS?id=${item.id}`)
    },
    signIn (item) {
      Dialog.confirm({
        title: '签到',
        message: '是否进行签到？'
      })
        .then(() => {
          consultantOrderSignIn(item.id).then((res) => {
            Toast.success('签到成功')
            this.current = 1
            this.list = []
            this.List()
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    signOut (item) {
      Dialog.confirm({
        title: '签退',
        message: '确认此订单已经进行过咨询,并且签退吗？'
      })
        .then(() => {
          consultantOrderSignOut(item.id).then((res) => {
            this.current = 1
            this.list = []
            this.List()
            Toast.success('签退成功')
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    List () {
      consultantOrderPage({
        current: this.current,
        size: this.size,
        status: 2
      }).then((res) => {
        this.list = this.list.concat(res.data.data.records)
        this.total = res.data.data.total
        if (this.list.length === res.data.data.total) {
          this.loading = true
          this.finished = true
        }
      })
    },
    onLoad () {
      this.current++
      this.List()
    },
    onClickLeft () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
:deep {
  .van-field__control {
    margin-top: 30px;
    background-color: rgb(246, 246, 246);
    border-radius: 8px;
    width: 100%;
  }
  .van-button--primary {
    color: #333;
    width: 43%;
    height: 37px;
    background-color: #fff;
    border-color: rgb(232, 232, 232);
    border-radius: 29px;
    margin: 0 10px 10px 0;
  }
  .van-button--info {
    color: rgb(43, 84, 163);
    height: 37px !important;
    width: 43%;
    background-color: #fff;
    border-color: rgb(43, 84, 163);
    border-radius: 29px;
    margin: 0 10px 10px 0;
  }
  .van-button--warning {
    color: #fff;
    height: 29px;
    width: 83px;
    background-color: rgb(43, 84, 163);
    border-color: rgb(43, 84, 163);
    border-radius: 29px;
    margin: 0 10px 10px 0;
  }
  .van-button--default {
    color: rgb(43, 84, 163);
    height: 29px;
    background-color: #fff;
    border-color: rgb(43, 84, 163);
    border-radius: 29px;
    margin: 0 10px 10px 0;
  }
  .van-nav-bar__title {
    font-weight: bold;
  }
  .van-popup--center {
    border-radius: 10px;
    width: 90%;
  }
  .van-tag--warning.van-tag--plain {
    color: rgb(255, 173, 63);
    border-radius: 5px;
    font-size: 11px;
  }
}
.userInfo {
  border-radius: 10px;
  width: 95%;
  margin: auto;
  background-color: #fff;
  .flex {
    padding: 5px 15px 5px 15px;
    display: flex;
    font-size: 16px;
    justify-content: space-between;
    .left {
      color: rgb(87, 87, 87);
    }
  }
}
.timeInfos {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  padding: 15px 15px 10px 9px;
}
.times {
  font-size: 16px;
  color: rgb(43, 84, 163);
  font-weight: bold;
}
.msg {
  font-size: 14px;
  text-align: left;
}
.index {
  min-height: 100vh;
}
.cards {
  width: 95%;
  margin: auto;
  margin-top: 10px;
  border-radius: 10px;
  padding-bottom: 5px;
  background-color: #fff;
  .imgs {
    margin-left: 13px;
    margin-top: 5px;
  }
  .info {
    font-size: 13px;
    margin: 5px 0 7px 7px;
    text-align: left;
    width: 100%;
  }
  .mony {
    flex: 1;
    text-align: right;
    margin-top: 6px;
    color: rgb(1, 81, 166);
  }
  .time {
    display: flex;
    font-size: 16px;
    padding: 16px 15px 10px 15px;
    color: rgb(104, 104, 104);
    .times {
      color: rgb(43, 84, 163);
      font-weight: normal;
    }
  }
}
</style>
