<template>
  <div class="center" style=" margin-top: 20%">
    <img
      src="@/assets/wx.png"
      style="width: 70px"
      height="70px;"
      alt=""
      srcset=""
    />
    <div>授权中......</div>
    <!-- <van-button @click="login" round type="info">退出登录</van-button> -->
  </div>
</template>

<script>
import { wxCallback, wxlogout } from '@/api/login'
import { Toast } from 'vant'
export default {
  data () {
    return {
      timesId: null,
      oauthToken: '',
      state: ''
    }
  },
  created () {
    this.init()
  },
  methods: {
    login () {
      wxlogout().then(res => {
        window.localStorage.clear()
        this.$router.replace('/')
      }).catch(res => {
        console.log(res)
      })
    },
    init: function () {
      const oauthToken = this.$route.query.code
      const state = this.$route.query.state
      this.oauthToken = oauthToken
      this.state = state
      // 测试环境还是走测试登录
      if (window.location.href.indexOf('answer123.com') !== -1) {
        console.log(2)
        // return
      }
      // 正式环境走正常登录
      if (oauthToken) {
        console.log(this.oauthToken)
        console.log(this.state)
        wxCallback({ code: this.oauthToken, state: this.state }).then(res => {
          if (res.data.identity === 0) {
            window.localStorage.setItem('refresh_token', res.data.refresh_token.token)
            window.localStorage.setItem('avatar', res.data.avatar)
            window.localStorage.setItem('wechat_qr', res.data.wechat_qr)
            window.localStorage.setItem('phone', res.data.phone)
            window.localStorage.setItem('access_token', res.data.access_token.token)
            window.localStorage.setItem('gender', res.data.gender)
            window.localStorage.setItem('identity', res.data.identity)
            window.localStorage.setItem('name', res.data.name)
            this.$router.replace('/home')
            Toast.success('登陆成功')
          } else {
            window.localStorage.setItem('refresh_token', res.data.refresh_token.token)
            window.localStorage.setItem('avatar', res.data.avatar)
            window.localStorage.setItem('phone', res.data.phone)
            window.localStorage.setItem('access_token', res.data.access_token.token)
            window.localStorage.setItem('gender', res.data.gender)
            window.localStorage.setItem('identity', res.data.identity)
            window.localStorage.setItem('nickName', res.data.name)
            this.$router.replace('/counselorHome')
            Toast.success('登陆成功')
          }
        }).catch(res => {
        })
      }
    },
    async getLoginCount () {
      await this.$router.replace('/phone')
    }
  },
  beforeDestroy () {
    clearTimeout(this.timesId)
  }
}
</script>

<style scoped lang="scss">
.center{
  text-align: center;
}
</style>
