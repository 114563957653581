<template>
  <div class="index" style="background-color: rgb(232, 232, 232); height: 100%;min-height: 100vh;padding-bottom:10px;">
    <van-nav-bar
      fixed
      placeholder
      @click-left="onClickLeft"
      title="已确认"
      left-arrow
    />
    <div class="cards">
      <div class="msg">
        <span>咨询信息</span>
      </div>
      <div style="display: flex">
        <div class="imgs">
          <van-image
            width="100px"
            height="100px"
            fit="cover"
            :src="details.avatar"
          />
        </div>
        <div class="info">
          <div style="display: flex">
            <div>
              <span style="font-size: 16px; font-weight: 400">{{
                details.name
              }}</span>
            </div>
            <div>
              <img
                v-if="details.isShowQualificationIcon === true"
                style="width: 57px; height: 20px; margin: 3px 0 0 7px"
                src="@/assets/renzhen14.png"
              />
            </div>
          </div>
          <div style="font-size: 12px; margin: 5px 0 5px 0; text-align: left">
            <span>{{ details.introduce }}</span>
          </div>

          <div style="font-size: 12px; margin: 5px 0 5px 0; text-align: left">
            <span class="tag">{{ details.consultantTypes }}</span>
          </div>
          <div style="display: flex; margin: 7px 0 7px 0">
            <div style="display: flex; margin-left: auto">
              <div>
                <van-tag plain type="warning">中铁建员工可享优惠</van-tag>
              </div>
              <div>
                <van-tag plain type="warning" style="margin-left: 5px"
                  >首次优惠</van-tag
                >
              </div>
            </div>
            <div class="mony" style="margin-right: 11px">
              ¥<span style="font-size: 18px">{{ details.normalPrice }}</span>
            </div>
          </div>
        </div>
      </div>

       <div class="time">
        <div><span>预约咨询时间</span></div>
        <div>
          <span class="times"
            >{{ details.reservationDay }} {{ details.reservationStartTime }}-{{
              details.reservationEndTime
            }}</span
          >
        </div>
      </div>
    </div>
    <div class="personInfo">
      <div class="msg">
        <span>来访信息</span>
      </div>
      <div>
        <div class="flexinfo">
          <div class="leftText" style="padding-left: 11px">
            <span>来访人姓名</span>
          </div>
          <div style="padding-right: 11px">
            <span>{{ details.comingName }}</span>
          </div>
        </div>
        <div class="flexinfo">
          <div class="leftText" style="padding-left: 11px">
            <span>来访人性别</span>
          </div>
          <div style="padding-right: 11px">
            <span v-if="details.comingGender === 1">男</span>
            <span v-else>女</span>
          </div>
        </div>
        <div class="flexinfo">
          <div class="leftText" style="padding-left: 11px">
            <span>联系方式</span>
          </div>
          <div style="padding-right: 11px">
            <span>{{ details.comingPhone }}</span>
          </div>
        </div>
        <div class="flexinfo">
          <div class="leftText" style="padding-left: 11px">
            <span>咨询方向</span>
          </div>
          <div style="padding-right: 11px">
            <span>{{ details.consultType }}</span>
          </div>
        </div>
        <div class="time">
          <div
            class="leftText"
            style="text-align: left; width: 55%; padding-left: 11px"
          >
            <span>备注</span>
          </div>
          <div style="text-align: right; width: 45%; padding-right: 11px">
            <span class="times"
              >{{details.note}}</span
            >
          </div>
        </div>
        <div class="flexinfo" style="padding-bottom: 15px">
          <div class="leftText" style="padding-left: 11px">
            <span>微信二维码</span>
          </div>
          <div style="padding-right: 11px">
            <div class="imgs">
              <van-image
                width="64px"
                height="64px"
                fit="cover"
                :src="details.wechatQr"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="personInfo">
      <div class="msg">
        <span>价格明细</span>
      </div>
      <div>
        <div class="flexinfo">
          <div class="leftText" style="padding-left: 11px">
            <span>订单总价</span>
          </div>
          <div style="padding-right: 11px">
            <span>¥{{ details.totalPrice }}</span>
          </div>
        </div>
        <div class="flexinfo">
          <div class="leftText" style="padding-left: 11px">
            <span>订单服务次数</span>
          </div>
          <div style="padding-right: 11px">
            <span>1次</span>
          </div>
        </div>
        <div class="flexinfo">
          <div class="leftText" style="padding-left: 11px">
            <span>优惠次数</span>
          </div>
          <div style="padding-right: 11px">
            <span
              >1次 <img style="width: 10px" src="@/assets/you.png" alt=""
            /></span>
          </div>
        </div>
        <div class="flexinfo">
          <div class="leftText" style="padding-left: 11px">
            <span>合计</span>
          </div>
          <div style="padding-right: 11px">
            <span>¥{{ details.totalPrice }}</span>
          </div>
        </div>
        <div class="flexinfo">
          <div class="leftText" style="padding-left: 11px">
            <span>订单编号</span>
          </div>
          <div style="padding-right: 11px">
            <span>{{ details.id }}</span>
          </div>
        </div>
        <div class="flexinfo">
          <div class="leftText" style="padding-left: 11px">
            <span>付款时间</span>
          </div>
          <div style="padding-right: 11px">
            <span>{{details.payTime}}</span>
          </div>
        </div>
        <div class="flexinfo" style="padding-bottom: 15px">
          <div class="leftText" style="padding-left: 11px">
            <span>确认时间</span>
          </div>
          <div style="padding-right: 11px">
            <span>{{details.comfirmTime}}</span>
          </div>
        </div>
      </div>
    </div>
    <div style="height:25px;"></div>
    <div class="btn">
      <div style="margin-left:10px;">
        <van-button @click="yuyue" type="default">取消预约</van-button>
      </div>
      <div style="margin-right:10px;">
        <van-button style="" type="info" @click="postpone"
          >延期预约</van-button
        >
      </div>
    </div>
    <van-popup
      v-model="show"
      closeable
      close-icon="close"
      position="bottom"
      :style="{ height: '30%' }"
    >
      <div>
        <van-field
          v-model="message"
          rows="4"
          autosize
          type="textarea"
          placeholder="请输入取消预约原因"
        />
      </div>
      <div style="text-align: center">
        <van-button
          @click="no"
          style="
            color: #333;
            width: 43%;
            height: 37px;
            background-color: #fff;
            border-color: rgb(232, 232, 232);
            border-radius: 29px;
            margin: 0 10px 10px 0;
          "
          type="primary"
          >暂不取消</van-button
        >
        <van-button
          style="
            color: #fff;
            height: 37px !important;
            width: 43%;
            background-color: rgb(43, 84, 163);
            border-color: rgb(43, 84, 163);
            border-radius: 29px;
            margin: 0 10px 10px 0;
          "
          @click="yes"
          type="info"
          >确定取消</van-button
        >
      </div>
    </van-popup>
  </div>
</template>
<script>
import { consultantOrderCancel, initOrderDetail } from '@/api/user'
import { Dialog, Toast } from 'vant'
export default {
  data () {
    return {
      orderId: '',
      details: {},
      show: false,
      message: ''
    }
  },
  created () {
    Toast.loading({
      message: '加载中...',
      forbidClick: true
    })
    this.orderId = this.$route.query.id
    this.detail()
  },
  methods: {
    postpone () {
      this.$router.push(`/postponeS?id=${this.details.counselorId}&ids=${this.details.id}`)
    },
    detail () {
      initOrderDetail(this.orderId).then((res) => {
        this.details = res.data.data
        console.log(this.details)
      })
    },
    no () {
      this.show = false
      this.message = ''
    },
    yuyue () {
      this.show = true
    },
    yes () {
      Dialog.confirm({
        title: '取消预约',
        message: '确定取消预约吗？'
      })
        .then(() => {
          consultantOrderCancel({
            id: this.details.id,
            reason: this.message
          }).then((res) => {
            this.show = false
            this.message = ''
            Toast.success('取消成功')
            this.$router.go(-1)
          })
          // on confirm
        })
        .catch(() => {
          // on cancel
        })
    },
    onClickLeft () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
:deep {
  .van-field__control {
    margin-top: 30px;
    background-color: rgb(246, 246, 246);
    border-radius: 8px;
    width: 100%;
  }
  .van-button--default {
    color: rgb(43, 84, 163);
    height: 29px;
    background-color: #fff;
    border-color: rgb(43, 84, 163);
    border-radius: 29px;
    margin: 0 10px 10px 0;
  }
  .van-button--info {
    color: #fff;
    height: 29px;
    background-color: rgb(43, 84, 163);
    border-color: rgb(43, 84, 163);
    border-radius: 29px;
    margin: 0 10px 10px 0;
  }
  .van-nav-bar__title {
    font-weight: bold;
  }
  .van-popup--center {
    text-align: center;
  }
  .van-popup--bottom {
    text-align: center;
  }
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160%;
  width: 100%;
}
.btn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
:deep {
  .van-popup--center {
    border-radius: 10px;
    width: 90%;
  }
  .van-button--primary {
    color: #fff;
    height: 29px;
    margin-bottom: 10px;
    background-color: rgb(43, 84, 163);
    border-radius: 29px;
    border: 1px solid rgb(43, 84, 163);
  }
  .van-tag--warning.van-tag--plain {
    color: rgb(255, 173, 63);
    border-radius: 5px;
    font-size: 11px;
  }
}
.succee {
  background-color: #fff;
  width: 95%;
}
.personInfo {
  width: 95%;
  margin: auto;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  font-size: 14px;
  .leftText {
    color: rgb(104, 104, 104);
    font-weight: 400;
  }
  .flexinfo {
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
  }
  .time {
    padding-top: 5px;
    display: flex;
    color: rgb(104, 104, 104);
  }
}
.msg {
  font-size: 17px;
  font-weight: 600;
  text-align: left;
  padding: 9px 0 0 11px;
}
.cards {
  width: 95%;
  margin: auto;
  margin-top: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  .imgs {
    margin-left: 13px;
    margin-top: 5px;
  }
  .info {
    font-size: 13px;
    margin: 5px 0 7px 7px;
    text-align: left;
    width: 100%;
  }
  .mony {
    flex: 1;
    text-align: right;
    margin-top: 6px;
    color: rgb(1, 81, 166);
  }
 .time {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    padding: 0 10px 15px 12px;
    color: rgb(104, 104, 104);
    .times {
      color: rgb(43, 84, 163);
    }
  }
}
</style>
