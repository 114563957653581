<template>
  <div class="index">
    <van-nav-bar
      fixed
      placeholder
      @click-left="onClickLeft"
      title="立刻预约"
      left-arrow
    />
      <div class="infoMeg">
        <div class="info_div">
          <div>
            <span class="info">填写信息</span>
          </div>
          <div class="info2">
            <span>个人信息内容平台将严格保密，仅用于本次咨询服务</span>
          </div>
          <!--  -->
          <div>
            <div class="title">
              <span>来访者姓名：</span>
            </div>
            <div>
              <van-cell-group>
                <van-field
                  clearable
                  required
                  maxlength="6"
                  v-model="from.name"
                  placeholder="请输入来访者姓名"
                />
              </van-cell-group>
            </div>
          </div>
          <!--  -->
          <div>
            <div class="title">
              <span>来访者性别：</span>
            </div>
            <div>
              <van-field
                required
                readonly
                clickable
                name="picker"
                :value="from.sex"
                placeholder="点击选择性别"
                @click="sexShow = true"
              />
            </div>
          </div>
          <!--  -->
          <div>
            <div class="title">
              <span>咨询方向：</span>
            </div>
            <div>
              <van-field
                required
                readonly
                clickable
                name="picker"
                :value="from.direction"
                placeholder="点击选择咨询方向"
                @click="directionShow = true"
              />
            </div>
          </div>
          <!--  -->
          <div>
            <div class="title">
              <span>联系方式：</span>
            </div>
            <div>
              <van-cell-group>
                <van-field
                  maxlength="11"
                  clearable
                  required
                  type="number"
                  v-model="from.phone"
                  placeholder="请输入联系方式"
                />
              </van-cell-group>
            </div>
          </div>

           <div>
            <div class="title">
              <span>微信号：</span>
            </div>
            <div>
              <van-cell-group>
                <van-field
                  clearable
                  v-model="from.code"
                  placeholder="请输入微信号"
                />
              </van-cell-group>
            </div>
          </div>
           <!--  -->
          <div>
            <div class="title">
              <span>备注：</span>
            </div>
            <div>
              <van-cell-group>
                <van-field
                  v-model="from.message"
                  rows="1"
                  autosize
                  type="textarea"
                  show-word-limit
                  maxlength="50"
                  clearable
                  placeholder="请输入备注"
                />
              </van-cell-group>
            </div>
          </div>
          <!--  -->
          <div style="margin-top: 10px; height: 100px">
            <div style="float: left">
              <div class="title">上传微信二维码</div>
              <div style="font-size: 10px; color: rgb(98, 98, 98)">
                (用于客服添加)
              </div>
            </div>
            <div style="float: right">
              <van-uploader
                v-model="from.fileList"
                :max-count="1"
                :after-read="afterRead"
              />
            </div>
          </div>

        </div>
      </div>
      <div v-show="showTabbar" class="shadow">
        <div>
          <van-cell
            @click="yuyue"
            style="
              background-color: #fff !important;
              margin-top: 10px;
              font-size: 16px;
              color: rgb(1, 81, 166);
            "
            :title="titles"
            icon="passed"
            is-link
          />
          <div class="btns">
            <van-button style="margin-right: 15px" round type="info"
              >取消预约</van-button
            >
            <van-button
              style="margin-left: 15px"
              round
              type="info"
              @click="confirm"
              >确认预约</van-button
            >
          </div>
        </div>
      </div>
    <van-action-sheet
      cancel-text="取消"
      v-model="sexShow"
      :actions="columns"
      @select="onConfirm"
    />
    <van-action-sheet
      cancel-text="取消"
      v-model="directionShow"
      :actions="directionS"
      @select="onSelect"
    />
    <!-- 咨询方向选择器 -->
    <!-- 预约时间弹窗 -->
    <van-action-sheet v-model="show" title="选择预约时间">
      <van-tree-select
        :items="items"
        :active-id.sync="activeId"
        :main-active-index.sync="activeIndex"
        @click-nav="clickNav"
        @click-item="clickItem"
      />
    </van-action-sheet>
  </div>
</template>

<script>
import { putfile, getReserTime, getConsultType } from '@/api/appointment'
import { Toast, Dialog } from 'vant'
export default {
  data () {
    return {
      imgShow: false,
      directionId: '',
      one: '',
      two: '',
      titles: '选择预约时间',
      originHeight: 0,
      showTabbar: true,
      show: false,
      items: [],
      activeId: 0,
      activeIndex: 0,
      from: {
        sex: '',
        name: '',
        direction: '',
        phone: '',
        message: '',
        fileList: [],
        code: ''
      },
      columns: [{ name: '男' }, { name: '女' }],
      directionS: [],
      sexShow: false,
      directionShow: false,
      id: '',
      ids: ''
    }
  },
  created () {
    const name = window.localStorage.getItem('nameS')
    if (name !== null) {
      this.from.code = window.localStorage.getItem('code')
      this.from.message = window.localStorage.getItem('message')
      this.id = window.localStorage.getItem('counselorId')
      this.from.name = window.localStorage.getItem('nameS')
      this.from.sex = window.localStorage.getItem('sexS')
      this.directionId = window.localStorage.getItem('directionId')
      this.from.direction = window.localStorage.getItem('directionS')
      this.from.phone = window.localStorage.getItem('phoneS')
      this.imgShow = true
    } else {
      this.id = this.$route.query.id
    }
    getReserTime(this.id).then((res) => {
      this.one = res.data.data[0].day
      res.data.data.forEach((element) => {
        element.text = element.day
        element.children.forEach((elements) => {
          elements.text = elements.time
        })
      })
      this.items = res.data.data
    })
    getConsultType(this.id).then((res) => {
      console.log('咨询类型')
      this.directionS = res.data.data
    })
  },
  mounted () {
    this.originHeight =
      document.documentElement.clientHeight || document.body.clientHeight
    window.addEventListener('resize', this.handleResize)
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.scroll)
  },
  methods: {
    onSelect (item) {
      this.directionId = item.id
      this.from.direction = item.name
      this.directionShow = false
    },
    // 判断ios
    isIos () {
      const m = navigator.userAgent
      return /iP(hone|od|ad)/.test(m)
    },
    // ios使用，监听输入框聚焦
    searchFocus () {
      if (this.isIos()) {
        this.showTabbar = false
        console.log(this.showTabbar, 'ios隐藏底部栏')
      }
    },
    // ios使用，监听输入框失去焦点
    searchBlur () {
      if (this.isIos()) {
        this.showTabbar = true
        console.log(this.showTabbar, 'ios显示底部栏')
      }
    },
    // 安卓使用，监听键盘弹出，即窗口大小改变
    handleResize () {
      const resizeHeight =
        document.documentElement.clientHeight || document.body.clientHeight
      if (resizeHeight < this.originHeight) {
        // 当软键盘弹起，在此处操作
        if (!this.isIos()) {
          this.showTabbar = false
          console.log(this.showTabbar, '安卓隐藏底部栏')
        }
      } else {
        // 当软键盘收起，在此处操作
        if (!this.isIos()) {
          this.showTabbar = true
          console.log(this.showTabbar, '安卓显示底部栏')
        }
      }
    },
    // 确定
    confirm () {
      if (this.from.name === '') {
        Toast.fail('请填写姓名')
      } else if (this.from.sex === '') {
        Toast.fail('请选择性别')
      } else if (this.from.direction === '') {
        Toast.fail('请选择咨询方向')
      } else if (this.from.phone === '') {
        Toast.fail('请填写联系方式')
      } else if (this.ids === '') {
        Toast.fail('请选择预约时间')
      } else {
        window.localStorage.setItem('code', this.from.code)
        window.localStorage.setItem('message', this.from.message)
        window.localStorage.setItem('timeText', this.two)
        window.localStorage.setItem('directionId', this.directionId)
        window.localStorage.setItem('nameS', this.from.name)
        window.localStorage.setItem('sexS', this.from.sex)
        window.localStorage.setItem('directionS', this.from.direction)
        window.localStorage.setItem('phoneS', this.from.phone)
        window.localStorage.setItem('timeId', this.ids)
        this.$router.push(`/notarize?id=${this.id}`)
      }
    },
    yuyue () {
      this.show = true
    },
    clickNav (data) {
      this.one = this.items[data].day
      this.two = this.items[data].day + '\xa0' + this.items[data].week
    },
    clickItem (data) {
      Dialog.confirm({
        title: '确认预约时间',
        message: '确定选择这个时间预约咨询师了吗?'
      })
        .then(() => {
          this.two = this.two + '\xa0' + data.text
          this.titles = this.one + '\xa0' + data.text + '\xa0' + '已选预约时间'
          this.ids = data.id
          this.show = false
          console.log(data)
        })
        .catch(() => {
          this.activeId = 0
        })
    },
    // 图片上传
    afterRead (file) {
      putfile(file).then((res) => {
        window.localStorage.setItem('imgLink', res.data.data.link)
      })
    },
    onConfirm (value) {
      this.from.sex = value.name
      this.sexShow = false
    },
    onClickLeft () {
      window.localStorage.removeItem('counselorId')
      window.localStorage.removeItem('timeId')
      window.localStorage.removeItem('imgLink')
      window.localStorage.removeItem('nameS')
      window.localStorage.removeItem('sexS')
      window.localStorage.removeItem('directionS')
      window.localStorage.removeItem('phoneS')
      window.localStorage.removeItem('message')
      window.localStorage.removeItem('timeText')
      window.localStorage.removeItem('timeId')
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
:deep {
  .van-nav-bar__title {
    font-weight: bold;
  }
  .van-sidebar-item--select::before {
    background-color: rgb(1, 81, 166);
  }
  .van-tree-select__item--active {
    color: rgb(1, 81, 166);
  }
  .van-tree-select__item {
    text-align: left;
  }
  .van-action-sheet__header {
    font-weight: 600;
    text-align: left;
    margin-left: 15px;
  }
  .van-cell {
    background-color: rgb(246, 246, 246) !important;
    border-radius: 6px;
    padding: 5px 16px;
  }
  .van-button {
    height: 37px;
    width: 120px;
    margin-bottom: 10px;
    background-color: rgb(233, 233, 233);
    border: 1px solid rgb(233, 233, 233);
    color: #333;
    font-weight: bold;
  }
}
.btns {
  margin-top: 20px;
}
.index {
  height: 85vh;
  overflow: hidden;
  overflow-y: scroll;
  .shadow {
    text-align: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    border-radius: 14px;
    background-color: #fff;
    box-shadow: 0px -5px 10px 0px rgb(233, 233, 233);
  }
  .infoMeg {
    margin: 10px 10px 10px 10px;
    .info_div {
      text-align: left;
      .info {
        font-weight: bold;
        font-size: 15px;
      }
      .info2 {
        font-size: 12px;
        color: rgb(86, 86, 86);
        margin-top: 5px;
      }
    }
    .title {
      margin: 5px 0 5px 0;
      color: rgb(86, 86, 86);
      font-size: 14px;
    }
  }
}
</style>
