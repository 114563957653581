import request from '@/utils/request'
const token = window.localStorage.getItem('access_token')
export function wxLogin () {
  return request({
    url: '/api/weCom/render',
    method: 'get'
  })
}
// 回调信息
export function wxCallback (params) {
  return request({
    url: '/api/weCom/callback',
    method: 'get',
    params
  })
}
// 手机号登录
export function weComLogin (phone, pwd) {
  return request({
    url: `/api/weCom/login/${phone}/${pwd}`,
    method: 'get'
  })
}

// 退出登录
export function wxlogout () {
  return request({
    url: '/api/weCom/logout',
    method: 'get',
    headers: {
      'Blade-Auth': token
    }
  })
}
// 咨询师修改密码
export function changePwd (params) {
  return request({
    url: '/api/counselor/changePwd',
    method: 'get',
    params
  })
}
