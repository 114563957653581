<template>
  <div class="index" style="background-color: rgb(232, 232, 232); height: 100%;padding-bottom: 10px;">
    <van-nav-bar
      fixed
      placeholder
      @click-left="onClickLeft"
      title="反馈详情"
      left-arrow
    />
    <div class="cards">
      <div class="msg">
        <span>咨询信息</span>
      </div>
      <div style="display: flex">
        <div class="imgs">
          <van-image
            width="100px"
            height="100px"
            fit="cover"
            :src="details.avatar"
          />
        </div>
        <div class="info">
          <div style="display: flex">
            <div>
              <span style="font-size: 16px; font-weight: 400">{{
                details.name
              }}</span>
            </div>
            <div>
              <img
                v-if="details.isShowQualificationIcon === true"
                style="width: 57px; height: 20px; margin: 3px 0 0 7px"
                src="@/assets/renzhen14.png"
              />
            </div>
          </div>
          <div style="font-size: 12px; margin: 5px 0 5px 0; text-align: left">
            <span>{{ details.introduce }}</span>
          </div>

          <div style="font-size: 12px; margin: 5px 0 5px 0; text-align: left">
            <span class="tag">{{ details.consultantTypes }}</span>
          </div>
          <div style="display: flex; margin: 7px 0 7px 0">
            <div style="display: flex; margin-left: auto">
              <div>
                <van-tag plain type="warning">中铁建员工可享优惠</van-tag>
              </div>
              <div>
                <van-tag plain type="warning" style="margin-left: 3px"
                  >首次优惠</van-tag
                >
              </div>
            </div>
            <div class="mony" style="margin-right: 11px">
              ¥<span style="font-size: 18px">{{ details.normalPrice }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="time">
        <div style="text-align: left; width: 30%"><span>预约咨询时间</span></div>
        <div style="text-align: right; width: 70%">
          <span class="times"
            >{{ details.reservationDay }} {{ details.reservationStartTime }}-{{
              details.reservationEndTime
            }}</span
          >
        </div>
      </div>
    </div>
    <div class="personInfo" style="margin-bottom: 10px">
      <div class="msg">
        <div>
          <img style="width: 25px" src="@/assets/fankui2.png" alt="" />
        </div>
        <div style="margin: 1px 0 0 5px">
          <span>我的反馈</span>
        </div>
      </div>
      <div style="margin-left: 15px">
        <span>我的评分</span>
      </div>
      <div>
        <div ref="chart" style="height: 250px;width: 100%;"></div>
      </div>
    </div>
    <div class="personInfo">
      <div class="msg">
        <div>
          <img style="width: 25px" src="@/assets/zenduan.png" alt="" />
        </div>
        <div style="margin: 1px 0 0 5px">
          <span>反馈建议</span>
        </div>
      </div>
      <div class="diagnose">
        <div>
          <span>{{ texts }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
import { Toast } from 'vant'
import { counselorOrderDetail, counselorOrderGetFeedBack } from '@/api/counselor'
export default {
  data () {
    return {
      texts: '',
      option: {
        radar: {
          indicator: [
            { name: '抑郁', max: 100 },
            { name: '焦虑', max: 100 },
            { name: '偏执', max: 100 },
            { name: '分裂', max: 100 },
            { name: '冲动', max: 100 },
            { name: '强迫', max: 100 },
            { name: '边缘', max: 100 }
          ]
        },
        series: [
          {
            center: ['50%'],
            name: 'Budget vs spending',
            type: 'radar',
            areaStyle: {},
            data: [
              {
                value: [0, 0, 0, 0, 0, 0, 0]
                // 显示数值
                // label: {
                //   normal: {
                //     show: true,
                //     formatter: function (params) {
                //       return params.value
                //     }
                //   }
                // }
              }
            ]
          }
        ]
      },
      orderId: '',
      details: ''
    }
  },
  created () {
    Toast.loading({
      message: '加载中...',
      forbidClick: true
    })
    this.orderId = this.$route.query.id
    this.detail()
  },
  mounted () {
    this.getFeedBack()
    this.getEchartData()
  },
  methods: {
    getFeedBack () {
      counselorOrderGetFeedBack(this.orderId).then((res) => {
        this.texts = res.data.data.advice
        this.option.series[0].data[0].value[0] = res.data.data.depressionScore
        this.option.series[0].data[0].value[1] = res.data.data.anxietyScore
        this.option.series[0].data[0].value[2] = res.data.data.paranoidScore
        this.option.series[0].data[0].value[3] = res.data.data.splitScore
        this.option.series[0].data[0].value[4] = res.data.data.impulseScore
        this.option.series[0].data[0].value[5] = res.data.data.forceScore
        this.option.series[0].data[0].value[6] = res.data.data.edgeScore
        this.getEchartData()
      })
    },
    detail () {
      counselorOrderDetail(this.orderId).then((res) => {
        this.details = res.data.data
      })
    },
    getEchartData () {
      const chartDom = this.$refs.chart
      const myChart = echarts.init(chartDom)
      this.option && myChart.setOption(this.option)
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    my () {
      this.$router.push('/my')
    }
  }
}
</script>

<style lang="scss" scoped>
:deep {
  .van-tag--warning.van-tag--plain {
    color: rgb(255, 173, 63);
    border-radius: 5px;
    font-size: 11px;
  }
  .van-nav-bar__title {
    font-weight: bold;
  }
  .van-popup--center {
    text-align: center;
  }
  .van-popup--bottom {
    text-align: center;
  }
}
.chart{
  text-align: center;
}
.index {
  min-height: 100vh;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160%;
  width: 100%;
}
.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
:deep {
  .van-popup--center {
    border-radius: 10px;
    width: 90%;
  }
}
.succee {
  background-color: #fff;
  width: 95%;
}
.personInfo {
  width: 95%;
  margin: auto;
  border-radius: 10px;
  background-color: #fff;
  font-size: 14px;
  .diagnose {
    color: rgb(104, 104, 104);
    padding: 13px 24px 16px 14px;
  }
  .grade {
    padding: 3px 0 10px 14px;
    color: rgb(43, 84, 163);
  }
}
.msg {
  display: flex;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  padding: 9px 0 0 11px;
}
.cards {
  width: 95%;
  margin: auto;
  margin-top: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  .imgs {
    margin-left: 13px;
    margin-top: 5px;
  }
  .info {
    font-size: 13px;
    margin: 5px 0 7px 7px;
    text-align: left;
    width: 100%;
  }
  .mony {
    flex: 1;
    text-align: right;
    margin-top: 6px;
    color: rgb(1, 81, 166);
  }
  .time {
    display: flex;
    font-size: 16px;
    padding: 0 15px 10px 15px;
    color: rgb(104, 104, 104);
    .times {
      color: rgb(43, 84, 163);
    }
  }
}
</style>
