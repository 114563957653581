<template>
<div>
  <div class="index" style="background-color: rgb(232, 232, 232); height: 100%;padding-bottom: 10px;">
    <van-nav-bar
      fixed
      placeholder
      @click-left="onClickLeft"
      title="上传反馈"
      left-arrow
    />
    <div class="cards">
      <div class="msg">
        <div>
          <img style="width: 25px" src="@/assets/zixun.png" alt="" />
        </div>
        <div style="margin: 1px 0 0 5px">
          <span>咨询信息</span>
        </div>
      </div>
       <div style="display: flex">
        <div class="imgs">
          <van-image
            width="100px"
            height="100px"
            fit="cover"
            :src="details.avatar"
          />
        </div>
        <div class="info">
          <div style="display: flex">
            <div>
              <span style="font-size: 16px; font-weight: 400">{{
                details.name
              }}</span>
            </div>
            <div>
              <img
                v-if="details.isShowQualificationIcon === true"
                style="width: 57px; height: 20px; margin: 3px 0 0 7px"
                src="@/assets/renzhen14.png"
              />
            </div>
          </div>
          <div style="font-size: 12px; margin: 5px 0 5px 0; text-align: left">
            <span>{{ details.introduce }}</span>
          </div>

          <div style="font-size: 12px; margin: 5px 0 5px 0; text-align: left">
            <span class="tag">{{ details.consultantTypes }}</span>
          </div>
          <div style="display: flex; margin: 7px 0 7px 0">
            <div style="display: flex; margin-left: auto">
              <div>
                <van-tag plain type="warning">中铁建员工可享优惠</van-tag>
              </div>
              <div>
                <van-tag plain type="warning" style="margin-left: 3px"
                  >首次优惠</van-tag
                >
              </div>
            </div>
            <div class="mony" style="margin-right: 11px">
              ¥<span style="font-size: 18px">{{ details.normalPrice }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="time">
        <div style="text-align: left; width: 30%"><span>预约时间</span></div>
        <div style="text-align: right; width: 70%">
          <span>{{details.reservationDay}} {{details.reservationStartTime}}-{{details.reservationEndTime}}</span>
        </div>
      </div>
      <div class="time">
        <div style="text-align: left; width: 30%"><span>来访人姓名</span></div>
        <div style="text-align: right; width: 70%">
          <span>{{details.comingName}}</span>
        </div>
      </div>
      <div class="time">
        <div style="text-align: left; width: 30%"><span>咨询方向</span></div>
        <div style="text-align: right; width: 70%">
          <span>{{details.consultType}}</span>
        </div>
      </div>
    </div>
    <div class="personInfo" style="margin-bottom: 10px">
      <div class="msg">
        <div>
          <img style="width: 25px" src="@/assets/fankui2.png" alt="" />
        </div>
        <div style="margin: 1px 0 0 5px">
          <span>我的评分</span>
        </div>
      </div>
      <div>
        <div
          class="flex"
          style="font-size: 16px; font-weight: 400; margin: 5px 25px 0 25px"
        >
          <div>类型</div>
          <div>评分</div>
        </div>
        <div class="flex" style="margin: 0 10px 0 25px">
          <div style="margin-top: 12px">抑郁</div>
          <div>
            <van-field
              required
              maxlength="3"
              style="width: 74px"
              v-model="text.depressionScore"
              type="number"
              input-align="right"
            />
          </div>
        </div>
        <div class="flex" style="margin: 0px 10px 0 25px">
          <div style="margin-top: 12px">焦虑</div>
          <div>
            <van-field
              maxlength="3"
              required
              style="width: 74px"
              v-model="text.anxietyScore"
              type="number"
              input-align="right"
            />
          </div>
        </div>
        <div class="flex" style="margin: 0px 10px 0 25px">
          <div style="margin-top: 12px">偏执</div>
          <div>
            <van-field
              maxlength="3"
              required
              style="width: 74px"
              v-model="text.paranoidScore"
              type="number"
              input-align="right"
            />
          </div>
        </div>
        <div class="flex" style="margin: 0px 10px 0 25px">
          <div style="margin-top: 12px">分裂</div>
          <div>
            <van-field
              maxlength="3"
              required
              style="width: 74px"
              v-model="text.splitScore"
              type="number"
              input-align="right"
            />
          </div>
        </div>
        <div class="flex" style="margin: 0px 10px 0 25px">
          <div style="margin-top: 12px">冲动</div>
          <div>
            <van-field
              maxlength="3"
              required
              style="width: 74px"
              v-model="text.impulseScore"
              type="number"
              input-align="right"
            />
          </div>
        </div>
         <div class="flex" style="margin: 0px 10px 0 25px">
          <div style="margin-top: 12px">强迫</div>
          <div>
            <van-field
              maxlength="3"
              required
              style="width: 74px"
              v-model="text.forceScore"
              type="number"
              input-align="right"
            />
          </div>
        </div>
         <div class="flex" style="margin: 0px 10px 0 25px">
          <div style="margin-top: 12px">边缘</div>
          <div>
            <van-field
              maxlength="3"
              required
              style="width: 74px"
              v-model="text.edgeScore"
              type="number"
              input-align="right"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="personInfo">
      <div class="msg">
        <div>
          <img style="width: 25px" src="@/assets/zenduan.png" alt="" />
        </div>
        <div style="margin: 1px 0 0 5px">
          <span>反馈建议</span>
        </div>
      </div>
      <div class="diagnose">
        <van-field
          style="height: 100%;"
          v-model="text.advice"
          rows="4"
          autosize
          type="textarea"
          maxlength="300"
          placeholder="请输入反馈意见"
          show-word-limit
        />
      </div>
    </div>
    <div style="height:50px"></div>
    <div class="btn">
        <van-button @click="uploadFeedbackS" type="info">上传反馈</van-button>
      </div>
  </div>
  <!-- 预约支付弹窗 -->
    <van-popup v-model="show">
      <div class="succee">
        <div style="margin-top: 20px">
          <img
            style="width: 40px"
            src="@/assets/succeed.png"
            alt=""
            srcset=""
          />
        </div>
        <div>反馈成功</div>
        <hr />
        <div style="margin-top: 20px">您已完成反馈...</div>
        <div style="margin-top: 20px">
          <van-button
            @click="onClickLeft"
            style="width: 40%; height: 30px; border-radius: 10px"
            type="primary"
            size="normal"
            >返回</van-button
          >
          <van-button
            @click="Ikonw"
            style="width: 40%; height: 30px; border-radius: 10px"
            type="primary"
            size="normal"
            >反馈详情</van-button
          >
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { Toast } from 'vant'
import { counselorOrderDetail, counselorOrderFeedBack } from '@/api/counselor'

export default {
  data () {
    return {
      show: false,
      text: {
        depressionScore: '',
        anxietyScore: '',
        edgeScore: '',
        paranoidScore: '',
        splitScore: '',
        impulseScore: '',
        forceScore: '',
        advice: '',
        orderId: ''
      },
      orderId: '',
      details: {}
    }
  },
  created () {
    Toast.loading({
      message: '加载中...',
      forbidClick: true
    })
    this.orderId = this.$route.query.id
    this.detail()
  },
  methods: {
    Ikonw () {
      this.$router.push(`/feedbackDetails?id=${this.details.id}`)
    },
    uploadFeedbackS () {
      if (this.text.depressionScore === '' || this.text.depressionScore > 100) {
        Toast.fail('抑郁评分未填写或评分超过100')
      } else if (this.text.anxietyScore === '' || this.text.anxietyScore > 100) {
        Toast.fail('焦虑评分未填写或评分超过100')
      } else if (this.text.paranoidScore === '' || this.text.paranoidScore > 100) {
        Toast.fail('偏执评分未填写或评分超过100')
      } else if (this.text.splitScore === '' || this.text.splitScore > 100) {
        Toast.fail('分裂评分未填写或评分超过100')
      } else if (this.text.impulseScore === '' || this.text.impulseScore > 100) {
        Toast.fail('冲动评分未填写或评分超过100')
      } else if (this.text.forceScore === '' || this.text.forceScore > 100) {
        Toast.fail('强迫评分未填写或评分超过100')
      } else if (this.text.edgeScore === '' || this.text.edgeScore > 100) {
        Toast.fail('边缘评分未填写或评分超过100')
      } else if (this.text.advice === '') {
        Toast.fail('反馈建议未填写')
      } else {
        this.text.orderId = this.orderId
        counselorOrderFeedBack(this.text).then(res => {
          this.show = true
        })
      }
    },
    detail () {
      counselorOrderDetail(this.orderId).then((res) => {
        this.details = res.data.data
      })
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    my () {
      this.$router.push('/my')
    }
  }
}
</script>

<style lang="scss" scoped>
:deep {
  .van-button--primary {
    color: #fff;
    background-color:rgb(43, 84, 163);
    border: 1px solid rgb(43, 84, 163);
    border-radius: 20px;
    margin-bottom: 10px;
    margin-left: 10px;
}
  .van-button--info {
    color: #fff;
    width: 100%;
    background-color: rgb(43, 84, 163);;
    border-color: rgb(43, 84, 163);
    border-radius: 5px;
    margin: 0 10px 10px 0;

  }
  .van-cell {
    height: 40px;
  }
  .van-field__control {
    border-radius: 5px;
    background-color: rgb(246, 246, 246);
  }
  .van-tag--warning.van-tag--plain {
    color: rgb(255, 173, 63);
    border-radius: 5px;
    font-size: 11px;
  }
  .van-nav-bar__title {
    font-weight: bold;
  }
  .van-popup--center {
    text-align: center;
  }
  .van-popup--bottom {
    text-align: center;
  }
}
.btn{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  margin-top: 5px;
  text-align: center;
}
.chart {

  text-align: center;
}
.index {
  min-height: 100vh;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160%;
  width: 100%;
}
.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}
:deep {
  .van-popup--center {
    border-radius: 10px;
    width: 90%;
  }
}
.succee {
  background-color: #fff;
  width: 95%;
}
.personInfo {
  padding-bottom: 10px;
  width: 95%;
  margin: auto;
  border-radius: 10px;
  background-color: #fff;
  font-size: 14px;
}
.msg {
  display: flex;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  padding: 9px 0 0 11px;
}
.cards {
  width: 95%;
  margin: auto;
  margin-top: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: #fff;
  .imgs {
    margin-left: 13px;
    margin-top: 5px;
  }
  .info {
    font-size: 13px;
    margin: 5px 0 7px 7px;
    text-align: left;
    width: 100%;
  }
  .mony {
    flex: 1;
    text-align: right;
    margin-top: 6px;
    color: rgb(1, 81, 166);
  }
  .time {
    display: flex;
    font-size: 16px;
    padding: 0 15px 10px 15px;
    color: rgb(104, 104, 104);
    .times {
      color: rgb(43, 84, 163);
    }
  }
}
</style>
