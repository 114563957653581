<template>
  <div class="home">
    <!-- <van-nav-bar fixed placeholder title="预约提醒" left-arrow /> -->
    <div class="index">
      <van-list
      v-if="list.length !== 0"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多数据了"
        @load="onLoad"
        :immediate-check='false'
        offset="10"
      >
        <template >
          <div class="btnse" v-for="(item, index) in list" :key="index">
            <div class="cards">
              <div class="time">
                <span class="times"
                  >{{ item.reservationDay }} {{ item.reservationStartTime }}-{{
                    item.reservationEndTime
                  }}</span
                >
                <span style="margin-left: 6px">已预约</span>
              </div>

              <div style="display: flex">
                <div class="imgs">
                  <van-image
                    width="64px"
                    height="64px"
                    fit="cover"
                    :src="item.avatar"
                  />
                </div>
                <div class="info">
                  <div style="margin: 0 0 5px 0">
                    <span style="font-size: 16px">{{ item.name }}</span>
                    <img
                      v-if="item.isShowQualificationIcon === true"
                      style="width: 57px; height: 20px; margin: 2px 0 3px 7px"
                      src="@/assets/renzhen14.png"
                      mode=""
                    />
                  </div>

                  <div
                    style="font-size: 13px; margin: 0 0 7px 0; text-align: left"
                  >
                    <span>{{ item.introduce }}</span>
                  </div>

                  <div style="font-size: 13px; text-align: left">
                    {{ item.consultType }}
                  </div>
                </div>
              </div>

              <div style="text-align: right; padding: 0 11px 0 0">
                <van-button
                  v-if="item.signInTime === ''"
                  type="info"
                  @click="signIn(item)"
                  >签到</van-button
                >
                <van-button v-else type="info" @click="signOut(item)"
                  >签退</van-button
                >
              </div>
            </div>
          </div>
        </template>
      </van-list>
    </div>
    <div v-if="list.length === 0" class="btns">
      <van-button @click="yuyue" type="default">立刻预约</van-button>
    </div>
    <van-tabbar placeholder v-model="active">
      <van-tabbar-item icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item @click="yuyue" icon="friends-o">咨询师</van-tabbar-item>
      <van-tabbar-item @click="my" icon="manager-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant'
import {
  consultantPageWillStartOrder,
  consultantOrderSignOut,
  consultantOrderSignIn
} from '@/api/user'
export default {
  data () {
    return {
      active: 0,
      list: [],
      loading: false,
      finished: false,
      current: 1,
      size: 10,
      value: 3,
      total: ''
    }
  },
  created () {
    Toast.loading({
      message: '加载中...',
      forbidClick: true
    })
    this.List()
  },
  methods: {
    signIn (item) {
      Dialog.confirm({
        title: '签到',
        message: '是否进行签到？'
      })
        .then(() => {
          consultantOrderSignIn(item.id).then((res) => {
            Toast.success('签到成功')
            this.current = 1
            this.list = []
            this.List()
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    signOut (item) {
      Dialog.confirm({
        title: '签退',
        message: '确认此订单已经进行过咨询,并且签退吗？'
      })
        .then(() => {
          consultantOrderSignOut(item.id).then((res) => {
            this.current = 1
            this.list = []
            this.List()
            Toast.success('签退成功')
          })
        })
        .catch(() => {
          // on cancel
        })
    },
    List () {
      consultantPageWillStartOrder({
        current: this.current,
        size: this.size
      }).then((res) => {
        this.list = this.list.concat(res.data.data.records)
        this.total = res.data.data.total
        if (this.list.length === res.data.data.total) {
          this.loading = true
          this.finished = true
        }
      })
    },
    onLoad () {
      this.current++
      this.List()
    },
    yuyue () {
      this.$router.push('/preordered')
    },
    my () {
      this.$router.push('/my')
    }
  }
}
</script>

<style lang="scss" scoped>
:deep {
  .van-button--default {
    width: 146px;
    height: 40px;
    color: rgb(43, 84, 163);
    font-family: PingFang SC;
    font-size: 17px;
    font-weight: 600;
    background: rgb(255, 255, 255);
    border-radius: 32px;
  }
  .van-empty {
    height: 90vh;
  }
  .van-nav-bar__title {
    font-weight: bold;
  }
  .van-nav-bar__left {
    display: none;
  }
  .van-button--info {
    color: #fff;
    height: 29px;
    background-color: rgb(43, 84, 163);
    border-color: rgb(43, 84, 163);
    border-radius: 29px;
    margin: 0 10px 10px 0;
    width: 83px;
  }
}
.btns {
  width: 100%;
  position: fixed;
  text-align: center;
  bottom: 80px;
}
.home {
  background: url("@/assets/beijing2.png") repeat-y;
  background-position: center;
  background-size: 100%;
  background-attachment: fixed;
}
.index {
  overflow-y: scroll;
  height: 100%;
  min-height: 85vh;
  .btnse {
    width: 100%;
    position: fixed;
    text-align: center;
    bottom: 50px;
  }
}
.imgs {
  margin-left: 13px;
  margin-top: 5px;
}
.info {
  font-size: 13px;
  width: 100%;
  margin: 5px 8px 7px 7px;
  text-align: left;
}
.cards {
  width: 95%;
  margin: auto;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: rgb(242, 246, 251);
  .time {
    font-size: 12px;
    text-align: left;
    padding: 16px 0 0 13px;
    .times {
      color: rgb(43, 84, 163);
      font-size: 16px;
    }
  }
}
</style>
