<template>
  <div class="index">
    <van-nav-bar fixed placeholder title="咨询师" left-arrow />
    <van-empty v-if="total === 0" image="error" description="暂无数据" />
    <van-list
      v-else
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      :immediate-check='false'
      offset="1"
    >
      <div v-for="(item, index) in list" :key="index">
        <div class="user_card">
          <div style="margin:7px; 0 7px 7px">
            <van-image
              round
              width="50px"
              height="50px"
              fit="cover"
              :src="item.avatar"
            />
          </div>
          <div style="width: 100%">
            <div style="width: 100%; display: flex">
              <div class="left">
                <div>
                  <span style="font-size: 20px">{{ item.name }}</span>
                  <img
                    v-if="item.isShowQualificationIcon === true"
                    style="width: 57px; height: 20px; margin: 2px 0 0 7px"
                    src="@/assets/renzhen14.png"
                    mode=""
                  />
                </div>
                <span
                  style="
                    color: rgb(102, 102, 102);
                    font-size: 12px;
                    margin: 5px 0 0 7px;
                  "
                  >从业{{ item.workTime }}年</span
                >
              </div>
              <div class="mony">
                ¥<span style="font-size: 20px">
                  <span v-if="item.salePrice === 0">{{
                    item.normalPrice
                  }}</span>
                  <span v-else>{{ item.salePrice }}</span>
                </span>
              </div>
            </div>
            <div
              style="
                font-size: 13px;
                margin: 6px 0 6px 7px;
                text-align: left;
                width: 95%;
              "
            >
              <span>{{ item.introduce }}</span>
            </div>
            <div class="tags">
              <span
                class="tag"
                >{{ item.types}}</span
              >
            </div>
            <div style="text-align: left; margin: 7px 0 0 7px">
              <van-tag plain type="warning">中铁建员工可享优惠</van-tag>
            </div>
            <div class="time">
              <img
                style="width: 12px; height: 12px; margin-top: 3px"
                src="@/assets/time.png"
                mode=""
              />
              <span style="margin-left: 4px">可约时间:</span>
            </div>
            <div style="width: 100%; margin: 5px 5px 0 5px">
              <span
                v-for="(time, index1) in item.times"
                :key="index1"
                class="tag_left"
              >
                <van-tag type="success">{{ time.day.slice(5,100) }} {{ time.week }}</van-tag>
              </span>
            </div>
            <div class="manys_time">
              <span
                >{{ item.serviceCount }}人次咨询</span
              >
              <van-button round type="info" @click="immediately(item)"
                >查看咨询师</van-button
              >
            </div>
          </div>
        </div>
      </div>
    </van-list>
    <van-tabbar placeholder v-model="active">
      <van-tabbar-item @click="home" icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item icon="friends-o">咨询师</van-tabbar-item>
      <van-tabbar-item @click="my" icon="manager-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { counselorPage } from '@/api/user'
export default {
  data () {
    return {
      list: [],
      loading: false,
      finished: false,
      src: 'https://w.wallhaven.cc/full/dp/wallhaven-dpl3x3.jpg',
      active: 1,
      current: 1,
      size: 10,
      total: ''
    }
  },
  created () {
    Toast.loading({
      message: '加载中...',
      forbidClick: true
    })
    this.List()
  },
  methods: {
    List () {
      counselorPage({ current: this.current, size: this.size }).then((res) => {
        res.data.data.records.forEach(element => {
          element.types = element.consultTypes.join(' | ')
        })
        this.list = this.list.concat(res.data.data.records)
        this.total = res.data.data.total
        if (this.list.length === res.data.data.total) {
          this.loading = true
          this.finished = true
        }
      })
    },
    onLoad () {
      this.current++
      this.List()
    },
    immediately (item) {
      this.$router.push(`/predetails?id=${item.id}`)
    },
    home () {
      this.$router.push('/home')
    },
    my () {
      this.$router.push('/my')
    }
  }
}
</script>

<style lang="scss" scoped>
:deep {
  .van-tag--primary {
    background-color: rgb(239, 247, 255);
    border-radius: 3px;
    color: rgb(1, 81, 166) !important;
  }

  .van-tag--success {
    margin-left: 2px;
    margin-bottom:5px;
    background-color: rgb(239, 241, 255);
    border-radius: 3px;
    color: rgb(98, 67, 147) !important;
  }

  .van-nav-bar__title {
    font-weight: bold;
  }
  .van-tag--warning.van-tag--plain {
    color: rgb(255, 173, 63);
    border-radius: 5px;
    padding: 3px 3px 3px 3px;
  }
  .van-nav-bar__left {
    display: none;
  }
  .van-button--info {
    color: #fff;
    height: 30px;
    background-color: rgb(43, 84, 163);
    border-color: rgb(43, 84, 163);
    border-radius: 9px;
    margin: 5px 10px 0 10px;
  }
}
.index {
  background-color: #f5f6f8;
  height: 100%;
  min-height: 100vh;
  .manys_time {
    margin-right: auto;
    text-align: right;
    padding: 7px 0 10px 7px;
    font-size: 11px;
    color: rgb(102, 102, 102);
  }
  .time {
    display: flex;
    font-size: 13px;
    margin: 6px 0 5px 7px;
  }
  .tag_left {
    margin-left: 4px;
  }
  .tags {
    font-size: 11px;
    color: rgb(1, 81, 166);
    padding: 5px 5px 5px 0px;
    margin-left: 7px;
    text-align: left;
    .tag {
      border-radius: 5px;
      background-color: rgb(237, 243, 255);
      padding: 5px 5px 5px 5px;
    }
  }
  .left {
    margin: 7px 0 0 7px;
    display: flex;
    width: 70%;
  }
  .mony {
    text-align: right;
    color: rgb(1, 81, 166);
    width: 30%;
    margin: 9px 10px 0 0;
  }
  .user_card {
    height: 100%;
    width: 95%;
    background-color: #fff;
    border-radius: 8px;
    margin: auto;
    margin-top: 7px;
    display: flex;
    margin-bottom: 7px;
  }
}
</style>
