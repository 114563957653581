import Vue from 'vue'
import VueRouter from 'vue-router'
import preordered from '@/views/consultant/preordered/preorderedS' // 咨询师列表
import myInfo from '@/views/consultant/My/my_Info' // 我的
import predetails from '@/views/consultant/preordered/preDetails' // 咨询师详情
import home from '@/views/consultant/homeS' // 首页
import login from '@/views/loginS' // 登录
import phoneLogin from '@/views/phoneLogin'
import appointment from '@/views/consultant/appointmentS' // 在线预约
import postponeS from '@/views/consultant/postponeS' // 延期预约
import wxChat from '@/views/wxChatCode/wxChat' // 企业微信登录
import notarize from '@/views/consultant/notarizeS' // 确认预约
import myDetails from '@/views/consultant/My/myDetails' // 用户详细信息
import WechatID from '@/views/consultant/My/WechatID' // 绑定微信号
import namesId from '@/views/consultant/My/namesId' // 绑定姓名
import bindingCard from '@/views/consultant/My/cards/bindingCard' // 绑卡界面
import bindingHistory from '@/views/consultant/My/cards/bindingHistory' // 绑卡历史
import underway from '@/views/consultant/My/underwayS' // 我的 --- 进行中
import reservation from '@/views/consultant/My/reservation/my_reservation' // 我的 --- 我的预约
// 用户端预约详情
// 已预约
import appointmentS from '@/views/consultant/My/reservation/detailPage/appointmentS'
// 已确认
import confirmedS from '@/views/consultant/My/reservation/detailPage/confirmedS'
// 已取消
import canceledS from '@/views/consultant/My/reservation/detailPage/canceledS'
// 已咨询
import consultedS from '@/views/consultant/My/reservation/detailPage/consultedS'
// 已反馈
import feedbackdS from '@/views/consultant/My/reservation/detailPage/feedbackdS'
import myFeedback from '@/views/consultant/My/myFeedback.vue' // 我的反馈
import feedbackDetails from '@/views/consultant/My/feedbackDetails.vue' // 反馈详情
// 咨询师端
// 首页
import counselorHome from '@/views/counselor/homeS.vue'
// 我的
import myInfos from '@/views/counselor/myInfoS.vue'
// 我的详情
import counselorMyDetail from '@/views/counselor/myDetails.vue'
// 我的反馈
import myFeedbacks from '@/views/counselor/myFeedback.vue'
// 修改密码
import changePassword from '@/views/counselor/changePassword.vue'
// 详情
// 已确认
import counseloConfirmed from '@/views/counselor/detailPage/confirmedS'
// 已取消
import counseloCanceledS from '@/views/counselor/detailPage/canceledS'
// 已咨询
import counseloConsultedS from '@/views/counselor/detailPage/consultedS'
// 已反馈
import counseloFeedbackdS from '@/views/counselor/detailPage//feedbackdS'
// 待咨询
import toBeConsulted from '@/views/counselor/toBeConsulted.vue'
// 待反馈
import toBeFeedback from '@/views/counselor/toBeFeedback.vue'
import counseloFeedbackDetails from '@/views/counselor/feedbackDetails.vue'// 反馈详情
import uploadFeedback from '@/views/counselor/uploadFeedback.vue'
Vue.use(VueRouter)
const routes = [
  {
    path: '/phoneLogin',
    name: 'phoneLogin',
    component: phoneLogin
  },
  {
    path: '/home',
    name: 'home',
    component: home
  },
  {
    path: '/',
    name: 'login',
    component: login
  },
  // 咨询师端
  {
    path: '/changePassword',
    name: 'changePassword',
    component: changePassword
  },
  {
    path: '/uploadFeedback',
    name: 'uploadFeedback',
    component: uploadFeedback
  },
  {
    path: '/counseloFeedbackDetails',
    name: 'counseloFeedbackDetails',
    component: counseloFeedbackDetails
  },
  {
    path: '/toBeFeedback',
    name: 'toBeFeedback',
    component: toBeFeedback
  },
  {
    path: '/toBeConsulted',
    name: 'toBeConsulted',
    component: toBeConsulted
  },
  {
    path: '/myFeedbacks',
    name: 'myFeedbacks',
    component: myFeedbacks
  },
  {
    path: '/counselorMyDetail',
    name: 'counselorMyDetail',
    component: counselorMyDetail
  },
  {
    path: '/myInfos',
    name: 'myInfos',
    component: myInfos
  },
  {
    path: '/counseloFeedbackdS',
    name: 'counseloFeedbackdS',
    component: counseloFeedbackdS
  },
  {
    path: '/counseloConsultedS',
    name: 'counseloConsultedS',
    component: counseloConsultedS
  },
  {
    path: '/counseloCanceledS',
    name: 'counseloCanceledS',
    component: counseloCanceledS
  },
  {
    path: '/counseloConfirmed',
    name: 'counseloConfirmed',
    component: counseloConfirmed
  },
  {
    path: '/counselorHome',
    name: 'counselorHome',
    component: counselorHome
  },
  // 咨询者端
  {
    path: '/names',
    name: 'names',
    component: namesId
  },
  {
    path: '/postponeS',
    name: 'postponeS',
    component: postponeS
  },
  {
    path: '/feedbackDetails',
    name: 'feedbackDetails',
    component: feedbackDetails
  },
  {
    path: '/myFeedback',
    name: 'myFeedback',
    component: myFeedback
  },
  {
    path: '/feedbackdS',
    name: 'feedbackdS',
    component: feedbackdS
  },
  {
    path: '/consultedS',
    name: 'consultedS',
    component: consultedS
  },
  {
    path: '/canceledS',
    name: 'canceledS',
    component: canceledS
  },
  {
    path: '/confirmedS',
    name: 'confirmedS',
    component: confirmedS
  },
  {
    path: '/appointmentS',
    name: 'appointmentS',
    component: appointmentS
  },
  {
    path: '/reservation',
    name: 'reservation',
    component: reservation
  },
  {
    path: '/underway',
    name: 'underway',
    component: underway
  },
  {
    path: '/bindingHistory',
    name: 'bindingHistory',
    component: bindingHistory
  },
  {
    path: '/WechatID',
    name: 'WechatID',
    component: WechatID
  },
  {
    path: '/bindingCard',
    name: 'bindingCard',
    component: bindingCard
  },
  {
    path: '/myDetails',
    name: 'myDetails',
    component: myDetails
  },
  {
    path: '/notarize',
    name: 'notarize',
    component: notarize
  },
  {
    path: '/wxChat',
    name: 'wxChat',
    component: wxChat
  },
  {
    path: '/appointment',
    name: 'appointment',
    component: appointment
  },
  {
    path: '/preordered',
    name: 'preordered',
    component: preordered
  },
  {
    path: '/my',
    name: 'my',
    component: myInfo
  },
  {
    path: '/predetails',
    name: 'predetails',
    component: predetails
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
