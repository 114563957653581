<template>
  <div class="index">
    <van-nav-bar
      fixed
      placeholder
      @click-left="onClickLeft"
      title="绑卡历史"
      left-arrow
    />
    <van-empty
      style="background-color: #fff;margin-top: 10px;min-height:92vh;"
      v-if="total === 0"
      image="error"
      description="暂无数据"
    />
    <van-list
    v-if="total > 0"
    :immediate-check='false'
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
      offset='10'
    >
    <div v-for="(item, index) in list" :key="index">
    <div class="user_card">
      <div class="numinfo">
        <div class="num">
          <div>
            <img
              style="width: 30px; height: 30px"
              src="@/assets/kahao.png"
              alt=""
              srcset=""
            />
          </div>
          <div style="padding-top: 5px; margin-left: 10px">
            <span>卡号</span>
            <span style="margin-left: 4px">{{item.cardNumber}}</span>
          </div>
        </div>
        <div style="padding-top: 5px; color: rgb(1, 81, 166); font-size: 14px;">
          <span v-if="item.isUsed === false">
            <span v-if="item.status === 1">未启用</span>
            <span v-if="item.status === 2">待使用</span>
            <span v-if="item.status === 3">已过期</span>
          </span>
          <span v-else>已使用</span>
        </div>
      </div>
      <van-divider />
      <div style="padding-bottom: 10px;">
        <div>
          <div style="padding: 0 12px 0 12px;" class="flexinfo">
            <div class="leftText">
              <span>绑定成功时间</span>
            </div>
            <div style="text-align: right">
              <span>{{item.bindTime}}</span>
            </div>
          </div>
          <div class="flexinfo">
            <div class="leftText">
              <span>服务次数</span>
            </div>
            <div style="text-align: right">
              <span>{{item.serviceTimes}}次</span>
            </div>
          </div>
          <div class="flexinfo">
            <div class="leftText">
              <span>有效起始日期</span>
            </div>
            <div style="text-align: right">
              <span>{{item.startTime}}</span>
            </div>
          </div>
          <div class="flexinfo" style="padding-bottom: 7px;">
            <div class="leftText">
              <span>有效结束日期</span>
            </div>
            <div style="text-align: right">
              <span>{{item.endTime}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </van-list>

  </div>
</template>

<script>
import { Toast } from 'vant'
import { cardBindHis } from '@/api/card'
export default {
  data () {
    return {
      list: [],
      loading: false,
      finished: false,
      src: 'https://w.wallhaven.cc/full/dp/wallhaven-dpl3x3.jpg',
      active: 1,
      current: 1,
      size: 10,
      total: ''
    }
  },
  created () {
    Toast.loading({
      message: '加载中...',
      forbidClick: true
    })
    this.List()
  },
  methods: {
    List () {
      cardBindHis({ current: this.current, size: this.size })
        .then((res) => {
          this.list = this.list.concat(res.data.data.records)
          this.total = res.data.data.total
          this.loading = false
          if (this.list.length === res.data.data.total) {
            this.loading = true
            this.finished = true
          }
        })
    },
    onLoad () {
      this.current++
      this.List()
    },
    onClickLeft () {
      this.$router.push('/my')
    }
  }
}
</script>

<style lang="scss" scoped>
:deep {
  .van-divider {
    width: 90%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 15px;
}
  .van-nav-bar__title {
    font-weight: bold;
  }
}
.index {
  padding-bottom: 10px;
  background-color: #f5f6f8;
  overflow: hidden;
  height: 100%;
  min-height: 100vh;
  .user_card {
    height: 100%;
    width: 95%;
    background-color: #fff;
    border-radius: 10px;
    margin: auto;
    margin-bottom: 10px;
    margin-top: 10px;
    .numinfo {
      display: flex;
      justify-content: space-between;
      padding: 10px 15px 0 15px;
      .num {
        display: flex;
      }
    }
    .leftText {
      color: rgb(104, 104, 104);
      font-weight: 400;
      font-size: 14px;
    }
    .flexinfo {
      font-size: 15px;
      padding: 10px 12px 0 12px;
      display: flex;
      justify-content: space-between;
    }
    .time {
      padding-top: 5px;
      display: flex;
      color: rgb(104, 104, 104);
    }
  }
}
</style>
