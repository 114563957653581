<template>
  <div class="index">
    <van-nav-bar @click-left="onClickLeft" title="绑定卡密" left-arrow />
    <div class="input">
      <van-field
        v-model="cardNumber"
        autosize
        label="卡号:"
        clearable
        placeholder="请输入卡号"
      />
    </div>
    <div class="input">
      <van-field
        v-model="cardPassword"
        autosize
        label="卡密:"
        clearable
        placeholder="请输入卡密"
      />
    </div>
    <div class="btn">
      <van-button @click="bindinng"  style="width: 90%;" type="info">确定绑定</van-button>
    </div>
  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
import { cardBinnding } from '@/api/card'
export default {
  data () {
    return {
      cardNumber: '',
      cardPassword: ''
    }
  },
  methods: {
    bindinng () {
      if (this.cardNumber === '') {
        Toast.fail('请先输入卡号')
      } else if (this.cardPassword === '') {
        Toast.fail('请先输入卡密')
      } else {
        Dialog.confirm({
          title: '绑定卡密',
          message: '是否绑定此卡密?'
        })
          .then(() => {
            cardBinnding(this.cardNumber, this.cardPassword).then(res => {
              if (res.data.code === 200) {
                this.cardNumber = ''
                this.cardPassword = ''
                Toast.success('绑定成功')
                setTimeout(() => {
                  this.$router.push('/bindingHistory')
                }, 1000)
              }
            }).catch(res => {
              Toast(res)
            })
          })
          .catch(() => {
            Toast('已取消')
          })
      }
    },

    onClickLeft () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
:deep {
  .van-nav-bar__title {
    font-weight: bold;
}
  .van-button--info {
    color: #fff;
    background-color: rgb(43, 84, 163);
    border-color: rgb(43, 84, 163);
    border-radius: 9px;
  }
  .van-cell {
    border-radius: 10px;
    width: 95%;
    margin-left: 10px;
    margin-left: 10px;
}
}
.index {
  min-height: 100vh;
  margin: auto;
  background-color: rgb(238, 238, 238);
  height: 100%;
  width: 100%;
  .input{
    margin-top: 10px;
  }
  .btn{
    text-align: center;
    margin-bottom: 10px;
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
  }
}
</style>
