<template>
  <div class="index">
    <van-nav-bar
      fixed
      placeholder
      @click-left="onClickLeft"
      title="我的预约"
      left-arrow
    />
    <!-- <reservedS></reservedS> -->
    <van-tabs  v-model="active">
      <van-tab title="已预约">
        <div v-if="active === 0" class="indexH">
          <reservedS></reservedS>
        </div>
      </van-tab>
      <van-tab title="已确认">
        <div v-if="active === 1" class="indexH">
          <confirmS></confirmS>
        </div>
      </van-tab>
      <van-tab title="已咨询">
        <div v-if="active === 2" class="indexH">
          <consultS></consultS>
        </div>
      </van-tab>
      <van-tab title="已反馈">
        <div v-if="active === 3" class="indexH">
          <feedbackS></feedbackS>
        </div>
      </van-tab>
      <van-tab title="已取消">
        <div v-if="active === 4" class="indexH">
          <cancelS></cancelS>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
// import { Toast } from 'vant'
import cancelS from './components/cancelS.vue'
import feedbackS from './components/feedbackS.vue'
import reservedS from './components/reservedS.vue'
import consultS from './components/consultS.vue'
import confirmS from './components/confirmS.vue'
export default {
  components: {
    reservedS,
    confirmS,
    consultS,
    feedbackS,
    cancelS
  },
  data () {
    return {
      active: 0
    }
  },
  created () {
    // Toast.loading({
    //   message: '加载中...',
    //   forbidClick: true
    // })
  },
  methods: {
    onClickLeft () {
      this.$router.push('/my')
    }
  }
}
</script>
<style lang="scss" scoped>
:deep {
  .van-tabs--line .van-tabs__wrap {
    top: 45px;
    position: fixed;
    height: 44px;
    width: 100%;
    z-index: 1000;
    margin-bottom: 40px;
  }
  .van-tab--active {
    color: rgb(43, 84, 163);
    font-size: 16px;
    font-weight: 500;
  }
  .van-tabs__line {
    background-color: rgb(43, 84, 163);
  }
  .van-nav-bar__title {
    font-weight: bold;
  }
  .van-button--info {
    color: #fff;
    background-color: rgb(43, 84, 163);
    border-color: rgb(43, 84, 163);
    border-radius: 29px;
    height: 29px;
  }
}
.index {
  margin: auto;
  background-color: rgb(232, 232, 232);
  height: 100%;
  width: 100%;
  .indexH {
    margin-top: 50px;
  }
}
</style>
